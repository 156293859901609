import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import Header_Logo from "../../assets/images/Varskart-Logo.png";
import HeaderNavbar from "./HeaderNavbar";
import "../../assets/css/header.css";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../redux/auth/auth.slice";
import { Notification } from "../genericActions";

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [searchInputValue, setSearchInputValue] = useState('')

  // const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setActiveLink(location.pathname.substring(1));
  }, [location.pathname]);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // You can adjust the threshold value based on when you want the navbar to become fixed
      const threshold = 100;

      // setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // State to track if the navbar is open or not
  const navbarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isNavbarOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        !event.target.classList.contains("navbar-toggler")
      ) {
        setIsNavbarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isNavbarOpen]);


  function moveToNext() {
    Notification("Logout Successfully!", "success");
  }

  const { pathname } = useLocation();

  function handleNavItemClick() {
    // Toggle Navbar when a Nav.Link is clicked
   
    const navbarToggle = document.getElementsByClassName('navbar-toggler')[0];
    navbarToggle.click();
  }
  function handleNavLogoClick() {
    // Toggle Navbar when a Nav.Link is clicked
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    const navbarToggle = document.getElementsByClassName('navbar-toggler')[0];
    // navbarToggle.click();
  }

  return (
    <div className={`navbar-fixed`}>
      {/* <div className={`mb-4 ${isNavbarFixed ? "navbar-fixed" : ""}`} > */}
      <Navbar expand="lg" className="navbar" ref={navbarRef}>
        <Container>
          <Navbar.Brand as={Link} to="/" className="navbar-brand mx-4" onClick={() => { handleNavLogoClick(); }}>
            <img src={Header_Logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsNavbarOpen(!isNavbarOpen)} />
          <Navbar.Collapse id="basic-navbar-nav" className="d-fex justify-content-center  ">
            <div className="d-flex">
            <Nav className="mr-auto">
              <Nav.Link
                as={Link}
                to="/about_us"
                className={`mx-1 fw-semibold ${activeLink === "abouts" ? "active" : ""
              }`}
                onClick={() => { setActiveLink("about"); handleNavItemClick();  setSearchInputValue("")}}
              >
                About Us
              </Nav.Link>
            
              <Nav.Link
                as={Link}
                to="/blogs"
                className={`mx-1 fw-semibold ${activeLink === "blogs" ? "active" : ""
                  }`}
                onClick={() => {setActiveLink("blog"); handleNavItemClick(); setSearchInputValue("")}}
              >
                BLOGS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className={`mx-1 fw-semibold ${activeLink === "contacts" ? "active" : ""
              }`}
                onClick={() => {setActiveLink("contact"); handleNavItemClick(); setSearchInputValue("")}}
              >
                CONTACT
              </Nav.Link>
            </Nav>
           
            </div>
          </Navbar.Collapse>
          <Navbar.Collapse id="basic-navbar-nav" className="d-fex justify-content-end ">
            
            <Nav className="d-flex justify-content-end align-items-center">
              {auth?.currentUser ? (
                <Button
                  variant="success"
                  className="btn text-white border-0 no-hover-effect"
                  onClick={() => {

                    dispatch(Logout(moveToNext)); 
                    handleNavItemClick();
                  }}
                >
                  Logout
                </Button>
              ) : (
                <>
                  <Nav.Link
                    as={Link}
                    to="/register"
                    className="mx-2 fw-semibold"
                    onClick={() => {handleNavItemClick();}}
                  >
                    Register
                  </Nav.Link>
                  <Nav.Link as={Link} to="/login">
                    <Button
                      variant="success"
                      className="btn text-white border-0 no-hover-effect"
                      onClick={() => {handleNavItemClick();}}

                    >
                      Sign In
                    </Button>
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <HeaderNavbar searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} />
    </div>
  );
}

export default Header;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import metaService from './meta.service';

const initialState = {
  getMetas: [],
  getMetasLoading: false,

};

export const GetMetas = createAsyncThunk('metas/get', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await metaService.getMetas(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const metaSlice = createSlice({
  name: 'metas',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetMetas.pending, (state) => {
        state.getMetasLoading = true;
      })
      .addCase(GetMetas.fulfilled, (state, action) => {
        state.getMetasLoading = false;
        state.getMetas = action.payload.data;
      })
      .addCase(GetMetas.rejected, (state, action) => {
        state.getMetasLoading = false;
        state.getMetas = action.payload;
      });
  },
});

export const { reset } = metaSlice.actions;

export default metaSlice.reducer;

import React from "react";
const Login = React.lazy(() => import("./authentication/Login.jsx"));
const Register = React.lazy(() => import("./authentication/register/Register.jsx"));
const Deshboard = React.lazy(() => import("./authentication/register/MyAccount.jsx"));
const Landing = React.lazy(() => import("./LandingPage/index.js"));
const ProductDetails = React.lazy(() => import("./ProductDetails/Index.js"));
const CategoriesLayout = React.lazy(() => import("./Categories/index.jsx"));
const Cart = React.lazy(() => import("./Cart/Index.js"));
const forgotPassword = React.lazy(() => import("../pages/authentication/ForgetPassword.js"));
const changePassword = React.lazy(() => import("../pages/authentication/ChangePassword.js"));
const rfq = React.lazy(() => import("../pages/rfq/index.js"));
const proceed_to_checkout = React.lazy(() => import("../pages/checkout/index.js"));
const aboutUs = React.lazy(() => import("../pages/Policies/AboutUs/index.js"));
const contactUs = React.lazy(() => import("../pages/ContactUs/index.js"));
const privacyPolicy = React.lazy(() => import("../pages/Policies/PrivacyPolicy/index.js"));
const deliveryPolicy = React.lazy(() => import("../pages/Policies/DeliveryPolicy/index.js"));
const termsCondition = React.lazy(() => import("../pages/Policies/TermsAndCondition/index.js"));
const becomeSupplier = React.lazy(() => import("../pages/Policies/BecomeSupplier/index.js"));
const orderReceived = React.lazy(() => import("../pages/OrderReceived/index.js"));
// const orderDetail = React.lazy(() => import("../pages/OrderDetail/detail/OrdersDetail.js"));
// const rfqList = React.lazy(() => import("../pages/rfq/rfq.js"));
// const rfqDetail = React.lazy(() => import("../pages/rfq/detail/RfqDetail.js"));
const addressDetail = React.lazy(() => import("../pages/UserAddress/index.js"));
const blogs = React.lazy(() => import("../pages/blogs/index.js"));
const blogDetail = React.lazy(() => import("../pages/blogs/detail/BlogDetail.js"));
const meetingRequest = React.lazy(() => import("../pages/Policies/RequestMeeting/RequestMeeting.js"));


const WEB_PAGES = {
  LANDING: Landing,
  LOGIN: Login,
  REGISTER: Register,
  DESHBOARD: Deshboard,
  PRODUCT_DETAILS: ProductDetails,
  CATEGORIES_LAYOUT: CategoriesLayout,
  CART: Cart,
  FORGOTPASSWORD: forgotPassword,
  CHANGEPASSWORD: changePassword,
  RFQ: rfq,
  PROCEED_To_CHECKOUT: proceed_to_checkout,
  ABOUT_US: aboutUs,
  PRIVACY_POLICY: privacyPolicy,
  DELIVERY_POLICY: deliveryPolicy,
  TERMS_AND_CONDITION: termsCondition,
  BECOME_SUPPLIER: becomeSupplier,
  // ORDER_LIST: orderList,
  // ORDER_DETAIL: orderDetail,
  // RFQ_LIST: rfqList,
  // RFQ_DETAIL: rfqDetail,
  ADDRESS_DETAIL: addressDetail,
  BLOGS: blogs,
  BLOG_DETAIL: blogDetail,
  ORDER_RECEIVED: orderReceived,
  CONTACT_US: contactUs,
  MEETING_REQUEST: meetingRequest,

};
export default WEB_PAGES;

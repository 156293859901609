import { Routes, Route } from "react-router-dom";
import WEB_PAGES from "./pages";
import { PATH } from "./utils/path";
import PublicRoute from "./authorization/PublicRoute.jsx";
// import ProtectedRoute from "./authorization/ProtectedRoute.jsx";

function RouterConfig() {
  
  return (
    <Routes>
      <Route
        path={PATH.LOGIN}
        element={<PublicRoute element={<WEB_PAGES.LOGIN />} />}
      />
      <Route
        path={PATH.REGISTER}
        element={<PublicRoute element={<WEB_PAGES.REGISTER />} />}
      />
      <Route
        path={PATH.DESHBOARD}
        element={<PublicRoute element={<WEB_PAGES.DESHBOARD />} />}
      />
      <Route
        path={PATH.LANDING}
        element={<PublicRoute element={<WEB_PAGES.LANDING />} />}
      />
      <Route
        path={PATH.CATEGORIES_LAYOUT}
        element={<PublicRoute element={<WEB_PAGES.CATEGORIES_LAYOUT />} />}
      />
      <Route
        path={PATH.PRODUCT_DETAILS}
        element={<PublicRoute element={<WEB_PAGES.PRODUCT_DETAILS />} />}
      />
      <Route
        path={PATH.CART}
        element={<PublicRoute element={<WEB_PAGES.CART />} />}
      />
    
      <Route
        path={PATH.PROCEED_To_CHECKOUT}
        element={<PublicRoute element={<WEB_PAGES.PROCEED_To_CHECKOUT />} />}
      />

      <Route
        path={PATH.REQUESTS}
        element={<PublicRoute element={<WEB_PAGES.REQUESTS />} />}
      />
 
      <Route
        path={PATH.FORGOTPASSWORD}
        element={<PublicRoute element={<WEB_PAGES.FORGOTPASSWORD />} />}
      />
      <Route
        path={PATH.CHANGEPASSWORD}
        element={<PublicRoute element={<WEB_PAGES.CHANGEPASSWORD />} />}
      />
       <Route
        path={PATH.RFQ}
        element={<PublicRoute element={<WEB_PAGES.RFQ />} />}
      />
       <Route
        path={PATH.ABOUT_US}
        element={<PublicRoute element={<WEB_PAGES.ABOUT_US />} />}
      />
      <Route
        path={PATH.PRIVACY_POLICY}
        element={<PublicRoute element={<WEB_PAGES.PRIVACY_POLICY />} />}
      />
      <Route
        path={PATH.DELIVERY_POLICY}
        element={<PublicRoute element={<WEB_PAGES.DELIVERY_POLICY />} />}
      />
      <Route
        path={PATH.TERMS_AND_CONDITION}
        element={<PublicRoute element={<WEB_PAGES.TERMS_AND_CONDITION />} />}
      />
      <Route
        path={PATH.BECOME_SUPPLIER}
        element={<PublicRoute element={<WEB_PAGES.BECOME_SUPPLIER />} />}
      />
     {/* <Route
        path={PATH.ORDER_LIST}
        element={<PublicRoute element={<WEB_PAGES.ORDER_LIST />} />}
      />
      <Route
        path={PATH.ORDER_DETAIL}
        element={<PublicRoute element={<WEB_PAGES.ORDER_DETAIL />} />}
      /> */}
      {/* <Route
        path={PATH.RFQ_LIST}
        element={<PublicRoute element={<WEB_PAGES.RFQ_LIST />} />}
      />
      <Route
        path={PATH.RFQ_DETAIL}
        element={<PublicRoute element={<WEB_PAGES.RFQ_DETAIL />} />}
      /> */}
      <Route
        path={PATH.ADDRESS_DETAIL}
        element={<PublicRoute element={<WEB_PAGES.ADDRESS_DETAIL />} />}
      />
      <Route
        path={PATH.BLOGS}
        element={<PublicRoute element={<WEB_PAGES.BLOGS />} />}
      />
      <Route
        path={PATH.BLOG_DETAIL}
        element={<PublicRoute element={<WEB_PAGES.BLOG_DETAIL />} />}
      />
      <Route
        path={PATH.ORDER_RECEIVED}
        element={<PublicRoute element={<WEB_PAGES.ORDER_RECEIVED />} />}
      />
      <Route
        path={PATH.CONTACT_US}
        element={<PublicRoute element={<WEB_PAGES.CONTACT_US />} />}
      />
      <Route
        path={PATH.MEETING_REQUEST}
        element={<PublicRoute element={<WEB_PAGES.MEETING_REQUEST />} />}
      />
    </Routes>
  );
}

// This component handles the itemId parameter and passes it to CATEGORIES_LAYOUT
// function SubCategoriesLayoutWithItemId() {
//   let { itemId } = useParams();

//   return <WEB_PAGES.SUB_CATEGORIES itemId={itemId} />;
// }

export default RouterConfig;

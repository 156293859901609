import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, useEffect } from "react";
import FullScreenLoader from "./authorization/FullScreenLoader";
import { BrowserRouter, useLocation } from "react-router-dom";
import RouterConfig from "./RouterConfig";
import { ProvideAuth } from "./authorization/ProvidedAuth";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ProvideAuth>
        <BrowserRouter>
        <ScrollToTop />
          <RouterConfig />
        </BrowserRouter>
      </ProvideAuth>
    </Suspense>
  );
}

export default App;

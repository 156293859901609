import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import { Cart, PersonFill, Search } from "react-bootstrap-icons";
import dropdown from "../../assets/svg/dropdown.svg";
import "../../assets/css/navbar.css";
import CartModal from "../cart/CartModal";
import { Link, createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../genericActions";
import { GetAllSearchItems } from "../../redux/searchItem/searchItem.slice";
import {
  FetchProductsByFilter,
  GetAllFilteredProducts,
  ResetAllProductsByFilter,
  ResetFetchProductsByFilter,
  ResetGetAllFilteredProducts,
} from "../../redux/product/product.slice";
import { AddUpdateProductToCart } from "../../redux/cart/cart.slice";
import { GetAllDepartments } from "../../redux/department/department.slice";
import { GetUserById } from "../../redux/users/user.slice";

import { PATH } from "../../utils/path";

const HeaderNavbar = ({searchInputValue, setSearchInputValue}) => {
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const [searchParams, setSearchParams] = useState({text:null,searchBy:null,data:null})
  // const [tagId, setTagId] = useState()
  const searchItem = useSelector((state) => state.searchItem);
  const product = useSelector((state) => state.product);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();

  useEffect(() => {
    if(location.pathname.includes("product-details")){
      setSearchInputValue("")
    }
  }, [location.pathname])
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold as needed
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  function moveToNext(response) {
    if (
      response.data.tag.length === 0 &&
      response.data.category.length === 0 &&
      response.data.brand.length === 0
    ) {
      dispatch(
        GetAllFilteredProducts({
          data: {
            pageNo: 1,
            size: 3,
            isPagination: true,
            id: 0,
            search: searchValue,
            departmentId: null,
            categoryId: null,
            subCategoryId: null,
            colorId: null,
            sizeId: null,
            miniPrice: 0,
            maxPrice: 10000000,
          },
        })
      );
      // dispatch(
      //   GetAllProducts({
      //     data: {
      //       pageNo: 1,
      //       size: 3,
      //       isPagination: true,
      //       id: 0,
      //       search: searchValue,
      //       departmentId: null,
      //       categoryId: null,
      //       subCategoryId: null,
      //       colorId: null,
      //       sizeId: null,
      //       miniPrice: 0,
      //       maxPrice: 10000000,
      //     },
      //   })
      // );
    }
  }
  useEffect(() => {
    if (searchValue)
       dispatch(
        GetAllSearchItems({ data: { searchText: searchValue }, moveToNext })
      )
  }, [searchValue]);

  const suggestionOverlayRef = useRef(null);
  useEffect(() => {
    const data = {
      pageNo: 1,
      size: 300,
      search: "",
      isPagination: false,
      id: 0,
      isEcom: true,
    };

    dispatch(GetAllDepartments({ data }));
  }, []);
  const handleClickOutside = (event) => {
    if (
      suggestionOverlayRef.current &&
      !suggestionOverlayRef.current.contains(event.target)
    ) {
      setHoveredSuggestion(null);
      setShowSearchSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    // if(searchInputValue?.length===0)
    // setShowSearchSuggestions(event.target.value ? true : false);
    dispatch(
      GetAllFilteredProducts({
        data: {
          pageNo: 1,
          size: 3,
          isPagination: true,
          id: 0,
          search: value,
          departmentId:null,
          categoryIds: null,
          subCategoryId: null,
          colorId: null,
          sizeId: null,
          miniPrice: 0,
          maxPrice: 10000000,
        },
      })
    );
    setShowSearchSuggestions(event.target.value && searchInputValue?.length>0 ? true : false);
    dispatch(ResetFetchProductsByFilter())
    dispatch(ResetAllProductsByFilter())
    dispatch(ResetGetAllFilteredProducts())
    setSearchValue(value);
  };

  const handleSuggestionHover = (id, type,data) => {
    if (type == "tag") {
      setSearchParams({text:data.tagName,searchBy:"tag",data:data})
      // setTagId(id)
      dispatch(
        FetchProductsByFilter({
          data: {
            pageNo: 1,
            size: 3,
            search: "",
            isPagination: true,
            id: id,
            departmentId: 0,
            categoryId: 0,
            subCategoryId: 0,
            colorId: 0,
            sizeId: 0,
            brandId: 0,
            tagId: id,
            miniPrice: 0,
            maxPrice: 0,
          },
        })
      );
    }else if (type === "category") {
      setSearchParams({text:data.categoryName,searchBy:"category",data:data})
      dispatch(
        GetAllFilteredProducts({
          data: {
            pageNo: 1,
            size: 3,
            isPagination: true,
            id: 0,
            search: "",
            departmentId:null,
            categoryIds: [""+id],
            subCategoryId: null,
            colorId: null,
            sizeId: null,
            miniPrice: 0,
            maxPrice: 10000000,
          },
        })
      );
    }else if (type === "brand") {
      setSearchParams({text:data.brandName,searchBy:"brand",data:data})
      dispatch(
        GetAllFilteredProducts({
          data: {
            pageNo: 1,
            size: 3,
            isPagination: true,
            id: 0,
            search: "",
            departmentId: null,
            categoryId: null,
            subCategoryId: null,
            colorId: null,
            sizeId: null,
            miniPrice: 0,
            maxPrice: 10000000,
            brandIds: [
              +id
            ],
          },
        })
      );
    }else if (type !== "brand") {
      // setSearchParams({text:data.nname,searchBy:"tag"})
      dispatch(
        GetAllFilteredProducts({
          data: {
            pageNo: 1,
            size: 3,
            isPagination: true,
            id: 0,
            search: "",
            departmentId: id,
            categoryId: null,
            subCategoryId: null,
            colorId: null,
            sizeId: null,
            miniPrice: 0,
            maxPrice: 10000000,
          },
        })
      );
    }
    
    // setHoveredSuggestion(index);
    // setHoveredProductData(getProductDetails(index));
  };

  const onSearchClick = (categoryId, event) => {
    navigate({
      pathname: PATH.CATEGORIES_LAYOUT,
      search: createSearchParams({
        searchKey: "" + searchValue
      }).toString()
    });
    // navigate(
    //   `/categories/${searchItem?.getAllSearchItems?.data?.category?.[0]?.id}?search=true`
    // );

    setShowSearchSuggestions(false);
  };

  const handleCategoryClick = (categoryId) => {
    setShowSearchSuggestions(false);
    // navigate(`/categories/${categoryId}?search=true`);
    navigate({
      pathname: PATH.CATEGORIES_LAYOUT,
      search: createSearchParams({
        categoryId: categoryId
      }).toString()
    });
  };
  const handleBrandClick = (brandId) => {
    setShowSearchSuggestions(false);
    // navigate(`/categories/${categoryId}?search=true`);
    navigate({
      pathname: PATH.CATEGORIES_LAYOUT,
      search: createSearchParams({
        brandId: brandId
      }).toString()
    });
  };



  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [showProductOverlay, setShowProductOverlay] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeout); // Clear timeout on component unmount
    };
  }, [typingTimeout]);
  const handleProductMouseEnter = () => {
    setShowSearchSuggestions(false)
    setShowProductOverlay(true);
  };
  const handleProductMouseLeave = () => {
    setShowProductOverlay(false);
  };

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const userId = auth?.currentUser?.userId || 0;
  useEffect(() => {
    if (auth.currentUser) {
      dispatch(
        GetUserById(userId)
      );
    }
  }, [dispatch, auth.currentUser, userId]);

  const handleMouseEnter = () => {
    auth?.currentUser === null && setShowOverlay(true);
  };
  const handleMouseLeave = () => {
    setShowOverlay(false);
  };
  const cart = useSelector((state) => state.cart);
  const department = useSelector((state) => state.department);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // const handleAccountButtonClick = () => {
  //   // Show the registration form when the account button is clicked
  //   navigate("/dashboard");
  //   setShowSearchSuggestions(false);
  // };
  const handleAccountButtonClick = () => {
    if (auth?.currentUser) { // Replace 'userIsLoggedIn' with your actual condition for checking if the user is logged in
      navigate("/dashboard");
      setShowSearchSuggestions(false);
    } else {
      setShowSearchSuggestions(false);
    }
  };
  
  const handleInputChange = (event) => {
    setSearchParams({...searchParams,text:event.target.value,searchBy:"text"})
    setSearchInputValue(event.target.value)
    clearTimeout(typingTimeout); // Clear the previous timeout

    // Set a new timeout to call doneTyping after 1 second of inactivity
    const timeout = setTimeout(() => {
      handleSearchChange(event);
    }, 1000); // Adjust this value as needed (in milliseconds)

    setTypingTimeout(timeout); // Store the new timeout
  };


  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  // Function to toggle search bar visibility
  const toggleSearchBar = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };
  // const [showRegisterForm, setShowRegisterForm] = useState(false);

  return (
    <Navbar className="navbar1 gradient text-white" expand="lg">
      <Container className="text-end">
        <Row className="w-100">
          <Col md={2}>
            <div className="d-none d-lg-inline">
              <Nav
                className="mx-1 d-flex align-items-start"
                onMouseEnter={handleProductMouseEnter}
                onMouseLeave={handleProductMouseLeave}
              >
                <Nav.Link
                  as={"span"}
                  // to="#"
                  className=" fw-semibold text-white text-nowrap "
                  style={{ cursor: "pointer" }}
                >
                  All Categories
                  <img
                    src={dropdown}
                    alt=""
                    style={{ width: "12px", height: "10px" }}
                    className=" mx-1 "
                  />
                </Nav.Link>
                {showProductOverlay && (
                  <div className="product-overlay text-start  rounded p-3">
                    <h6 className="fw-bold">All Categories</h6>
                    <div className=" w-100 border"></div>
                    <div className="my-3">
                      <Row>
                        {department?.getAllDepartments?.data &&
                          department?.getAllDepartments?.data.length > 0 ? (
                          department?.getAllDepartments?.data?.map(
                            (departmentDetail, index) => (
                              <Col key={index} md={4} sm={2}>
                                {/* <Link
                                  to={`/categories/${departmentDetail.id}`}
                                  style={{ textDecoration: "none" }}
                                > */}
                                <div
                                  className="product-item my-1 text-start "
                                  style={{
                                    fontSize: "13px",
                                    
                                  }}
                                  onClick={() => {
                                    navigate({
                                      pathname: PATH.CATEGORIES_LAYOUT,
                                      search: createSearchParams({
                                        departmentId: departmentDetail.id
                                      }).toString()

                                    });
                                    setSearchInputValue("")
                                  }}
                                >
                                 <label style={{ cursor: "pointer" }}>{departmentDetail.deptName}</label> 
                                </div>
                                {/* </Link> */}
                              </Col>
                            )
                          )
                        ) : (
                          <Col>
                            <p>No record found</p>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className=" w-100 border"></div>
                  </div>
                )}
              </Nav>
            </div>
          </Col>
          {/* Search  */}
          <Col md={6}>
            <div className="d-none d-lg-inline  ">
              <Nav className="d-flex align-items-center px-2 w-100 w-sm-auto">
                <Form.Control
                  type="text"
                  name="paymentTypeId"
                  placeholder="Search by Tags/Categories/Brands/Product"
                  onChange={handleInputChange}
                  value={searchInputValue}
                  onFocus={() => { searchValue?.length > 0 && setShowSearchSuggestions(true) }}
                  onBlur={() => {
                    dispatch(ResetFetchProductsByFilter())
                    dispatch(ResetAllProductsByFilter())
                    // dispatch(ResetGetAllFilteredProducts())
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.target.value?.length>0) {
                      navigate({
                        pathname: PATH.CATEGORIES_LAYOUT,
                        search: createSearchParams({
                          searchKey: "" + event.target.value
                          // categoryId: searchItem?.getAllSearchItems?.data?.category?.[0]?.id
                        }).toString()
                      });
                      window.scrollTo(0, 0);
                      setSearchValue("")
                      setShowSearchSuggestions(false)
                      // setSearchInputValue("")
                    }
                  }}
                />
                <button
                  className="bg-danger rounded-0 border-0 py-2"
                  type="button"
                  onClick={onSearchClick}
                >
                  <Search size={26} color="white" className="p-1" />
                </button>
              </Nav>
            </div>

            {showSearchSuggestions && (
              <div className="suggestion-overlay" ref={suggestionOverlayRef}>
                <Row>
                  <Col lg={5} className="border-end text-start ">
                  {searchItem?.getAllSearchItems?.data?.tag?.length>0 && <>
                      <h6 className="fw-bold">Suggestions</h6>
                      <div className="w-100 border"></div>
                      <div className="my-3">
                        {searchItem?.getAllSearchItems?.data?.tag?.map(
                          (suggestion, index) => (
                            <div>
                              <small
                                key={index}
                                className={`suggestion-item ${hoveredSuggestion === suggestion?.id
                                  ? "hovered"
                                  : ""
                                  }`}
                                // onClick={()=> navigate()}
                                onMouseEnter={() => {
                                  if (isMobile) {
                                    navigate({
                                      pathname: PATH.CATEGORIES_LAYOUT,
                                      search: createSearchParams({
                                        tagId: suggestion?.id
                                      }).toString()
                                    });
                                    setShowSearchSuggestions(false);

                                  } else {
                                    handleSuggestionHover(suggestion?.id, "tag",suggestion)
                                  }
                                }}
                                // onClick={() =>
                                //   handleSuggestionSelect(suggestion?.id)
                                // }
                                style={{ cursor: "pointer" }}
                              >
                                {suggestion.tagName}
                              </small>
                            </div>
                          )
                        )}
                      </div>
                  </>}
                    {searchItem?.getAllSearchItemsLoading ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="primary"
                          className="dzu-spinner-border"
                        />
                      </div>
                    ) : (
                      searchItem?.getAllSearchItems?.data?.category.length >
                      0 && (
                        <>
                          <h6 className="fw-bold">Product Categories</h6>
                          <div className="w-100 border"></div>
                        </>
                      )
                    )}
                    {searchItem?.getAllSearchItems?.data?.category?.map(
                      (categoryItem) => (
                        <div className="my-3">
                          <small
                            className="suggestion-item"
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() =>
                              handleSuggestionHover(categoryItem?.id,"category",categoryItem)
                            }
                            onClick={() =>
                              // navigate(
                              //   `/categories/${categoryItem?.id}?search=true`
                              // )
                              handleCategoryClick(categoryItem?.id)
                            }
                          >
                            {categoryItem?.categoryName}
                          </small>
                        </div>
                      )
                    )}
                    {searchItem?.getAllSearchItems?.data?.brand.length > 0 && (
                      <>
                        <h6 className="fw-bold">Brands</h6>
                        <div className="w-100 border"></div>
                      </>
                    )}
                    {searchItem?.getAllSearchItems?.data?.brand?.map(
                      (brandItem) => (
                        <div className="my-3">
                          <small
                            className="suggestion-item"
                            onMouseEnter={() =>
                              handleSuggestionHover(brandItem?.id, "brand",brandItem)
                            }
                            onClick={() =>
                              // navigate(
                              //   `/categories/${categoryItem?.id}?search=true`
                              // )
                              handleBrandClick(brandItem?.id)
                            }
                          >
                            {brandItem?.brandName}
                          </small>
                        </div>
                      )
                    )}
                  </Col>
                  {product?.fetchProductsByFilterLoading ||
                    product?.getAllFilteredProductsLoading ? (
                      <Col
                        lg={7}
                        className="align-items-center d-flex justify-content-center"
                      >
                        <Spinner
                        animation="border"
                        size="sm"
                        variant="primary"
                      />
                      </Col>
                      
                  ) : (
                    (product?.fetchProductsByFilter?.data?.length > 0 ||
                      product?.getAllFilteredProducts?.data?.length > 0) && (
                      <Col
                        lg={7}
                        className="suggestion-details d-none d-lg-inline"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Your detailed view code goes here */}
                        <Row className=" my-2 border-bottom align-items-center">
                          <Col md={8}>
                            <p
                              className=" p-0 m-0 text-muted"
                              style={{ fontSize: "11px" }}
                            >
                              Recommended Products for 
                              <p style={{fontWeight:"bold"}}>{searchParams.text?.toUpperCase()}</p>
                            </p>
                            <p className=" p-0 m-0 ">
                              {/* {hoveredProductData?.name} */}
                            </p>
                          </Col>
                          <Col md={4}>
                            <button className="my-2 ml-3 btn-primary rounded-lg py-1 text-nowrap "
                              onClick={() => {
                                let paramsObj=
                                  searchParams.searchBy==="text"?
                                    {searchKey:searchParams.text}:
                                     searchParams.searchBy==="tag"?{tagId:searchParams.data.id}:
                                      searchParams.searchBy==="category"?{categoryId:searchParams.data.id}:
                                        searchParams.searchBy==="brand" && {brandId:searchParams.data.id};
                                setShowSearchSuggestions(false);
                                navigate({
                                  pathname: PATH.CATEGORIES_LAYOUT,
                                  search: createSearchParams(paramsObj).toString()
                                });
                                // navigate({
                                //   pathname: PATH.CATEGORIES_LAYOUT,
                                //   search: createSearchParams({
                                //     tagId
                                //   }).toString()
                                // });
                              }}>
                              Shop All
                            </button>
                          </Col>
                        </Row>

                        {product?.fetchProductsByFilter?.data?.length > 0
                          ?product?.fetchProductsByFilter?.data?.map(
                            (productDetail) => (
                              <Row className="my-2 border-bottom align-items-center">
                                <Col
                                  md="6"
                                  onClick={() => {
                                    navigate(
                                      `/product-details/${productDetail?.id}`
                                    );
                                    setShowSearchSuggestions(false);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    width={40}
                                    className="rounded"
                                    src={productDetail?.itemImage?.[0]?.url}
                                    alt="Product Image"
                                  />
                          
                                  <small
                                    className="fw-bold"
                                    style={{ fontSize: "70%" }}
                                  >
                                    {productDetail?.productName}
                                  </small>
                                </Col>
                                <Col md="6" className="px-0">
                                  {/* Your detailed product view code goes here */}
                                  <Row>
                                    <Col md="12" className="px-0">
                                      <div className="d-flex justify-content-end align-items-center mx-1 w-auto">
                                        {(productDetail.salePrice > 0 && productDetail.isRFQRequired) && (
                                          <input
                                            type="number"
                                            className="w-25"
                                            placeholder="Qty"
                                            defaultValue={1}
                                            onChange={(e) => {
                                              setSelectedProduct({
                                                ...productDetail,
                                                quantity: +e.target.value,
                                              });
                                            }}
                                          />
                                        )}
                                        <div className="align-items-end d-flex flex-column justify-content-end">
                                          {productDetail.salePrice > 0 && productDetail.isRFQRequired && (
                                            <button
                                              className="ml-1 btn btn-success rounded-lg py-1 text-nowrap"
                                              onClick={() => {
                                                const finalData = {
                                                  salePrice:
                                                    selectedProduct?.salePrice ||
                                                    productDetail.salePrice,
                                                  quantity:
                                                    selectedProduct?.quantity ||
                                                    1,
                                                  productName:
                                                    selectedProduct?.productName ||
                                                    productDetail.productName,
                                                  productId:
                                                    selectedProduct?.id ||
                                                    productDetail.id,
                                                  productImageUrl:
                                                    selectedProduct
                                                      ?.itemImage?.[0]?.url ||
                                                    productDetail.itemImage?.[0]
                                                      ?.url ||
                                                    NO_IMAGE_FOUND,
                                                };
                                                dispatch(
                                                  AddUpdateProductToCart({
                                                    data:
                                                      finalData ||
                                                      selectedProduct,
                                                  })
                                                );
                                              }}
                                            >
                                              <small style={{ fontSize: "11px"}}> Add to Cart</small>
                                            </button>
                                          )}
                                          {productDetail.isRFQRequired && (
                                            <button
                                              className="ms-1 btn btn-success rounded-lg py-1 px-2 mt-1"
                                              style={{ minWidth: "90px" }}
                                              onClick={() => {
                                                if (auth?.currentUser?.userId) {
                                                  navigate(
                                                    `${PATH.RFQ.replace(":id", productDetail.id)}?productId=${productDetail.productId}&productName=${productDetail.productName}`,
                                                    {
                                                      state: {
                                                        isRFQRequired: productDetail.isRFQRequired,
                                                      },
                                                    }
                                                  );
                                                } else {
                                                  Notification("Please login to continue!", "error");
                                                }
                                              }}
                                            >
                                              <small style={{ fontSize: "11px"}}> Add RFQ</small>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )
                          )
                          
                          : product?.getAllFilteredProducts?.data.length > 0 &&
                          product?.getAllFilteredProducts?.data?.map(
                            (productDetail) => (
                              <Row className="my-2 border-bottom align-items-center">
                                <Col
                                  md="6"
                                  onClick={() => {
                                    navigate(
                                      `/product-details/${productDetail?.id}`
                                    );
                                    setSearchInputValue("")
                                    setShowSearchSuggestions(false);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    width={40}
                                    className="rounded"
                                    src={productDetail?.itemImage?.[0]?.url}
                                    alt="Product Image"
                                  />
                          
                                  <small
                                    className="fw-bold"
                                    style={{ fontSize: "70%" }}
                                  >
                                    {productDetail?.productName}
                                  </small>
                                </Col>
                                <Col md="6" className="px-0">
                                  {/* Your detailed product view code goes here */}
                                  <Row>
                                    <Col md="12" className="px-0">
                                      <div 
                                        className="align-items-end d-flex flex-column justify-content-end">
                                        {productDetail.isRFQRequired && productDetail.salePrice === 0 ? (
                                          <button
                                            className="ms-1 btn btn-success rounded-lg py-1 px-2 mt-1"
                                            style={{ minWidth: "100px" }}
                                            onClick={() => {
                                              if (auth?.currentUser?.userId) {
                                                navigate(
                                                  `${PATH.RFQ.replace(":id", productDetail.id)}?productId=${productDetail.productId}&productName=${productDetail.productName}`,
                                                  {
                                                    state: {
                                                      isRFQRequired: productDetail.isRFQRequired,
                                                    },
                                                  }
                                                );
                                              } else {
                                                Notification("Please login to continue!", "error");
                                              }
                                            }}
                                          >
                                            <small> Add RFQ</small>
                                          </button>
                                        ) : (
                                          <input
                                            type="number"
                                            className="w-25"
                                            placeholder="Qty"
                                            defaultValue={1}
                                            onChange={(e) => {
                                              setSelectedProduct({
                                                ...productDetail,
                                                quantity: +e.target.value,
                                              });
                                            }}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  {productDetail.salePrice > 0 && (
                                    <button
                                      className="mx-1 btn btn-success rounded-lg py-1 text-nowrap"
                                      onClick={() => {
                                        const finalData = {
                                          salePrice:
                                            selectedProduct?.salePrice ||
                                            productDetail.salePrice,
                                          quantity:
                                            selectedProduct?.quantity ||
                                            1,
                                          productName:
                                            selectedProduct?.productName ||
                                            productDetail.productName,
                                          productId:
                                            selectedProduct?.id ||
                                            productDetail.id,
                                          productImageUrl:
                                            selectedProduct
                                              ?.itemImage?.[0]?.url ||
                                            productDetail.itemImage?.[0]
                                              ?.url ||
                                            NO_IMAGE_FOUND,
                                        };
                                        dispatch(
                                          AddUpdateProductToCart({
                                            data:
                                              finalData ||
                                              selectedProduct,
                                          })
                                        );
                                      }}
                                    >
                                      <small> Add to Cart</small>
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}
                          
                      </Col>
                    )
                  )}
                </Row>
              </div>
            )}
          </Col>
          <Col md={4}>
            <div className="d-none d-lg-inline">

              <div className=" d-flex align-items-center justify-content-end  ">
                <Nav className="mx-1 d-none d-lg-inline">
                  <Button
                    variant="success"
                    className="btn text-white border-0 text-nowrap  no-hover-effect"
                    onClick={() => {
                      if (auth?.currentUser?.userId) {
                        navigate("/rfq");
                        setShowSearchSuggestions(false);
                      } else {
                        Notification("Please login to continue!", "error");
                      }
                    }}
                  >
                    <small className="fw-bold ">
                      REQUEST QUOTE
                    </small>
                  </Button>
                </Nav>

                <Nav
                  className="mx-1 text-center d-flex align-items-center "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className="rounded-circle bg-white text-center "
                    style={{ cursor: "pointer" }}
                  >
                    {auth?.currentUser === null ? (
                      <PersonFill size={30} className=" text-black" />
                    ) : (
                      <img
                        src={`https://ui-avatars.com/api/?name=${auth?.currentUser?.name}`}
                        width={"100%"}
                      />
                    )}
                  </div>
                  <div className="my-button-container">
                    <Button
                      className="bg-transparent text-nowrap fs-6  d-none d-lg-inline "
                      id="button-overlay"
                      onClick={handleAccountButtonClick}
                    >
                      {auth?.currentUser ? (
                        user?.getUserById?.firstName && user?.getUserById?.firstName.length > 10 ? (
                          <>{`${user?.getUserById?.firstName.slice(0, 10)}..`}</>
                        ) : (
                          <>{user?.getUserById?.firstName || "Account Name"}</>
                        )
                      ) : (
                        "Account Name"
                      )}
                      {/* {user?.getUserById?.firstName && user?.getUserById?.firstName.length > 10
                        ? `${user?.getUserById?.firstName.slice(0, 10)}..`
                        : user?.getUserById?.firstName || "Account Name"} */}
                    </Button>
                    {/* {showRegisterForm && <Register />} */}
                    {showOverlay && (
                      <div className="overlay rounded p-3">
                        <h6 className="fw-bold">Sign In on Varskart</h6>
                        <Button
                          className="w-75 fw-semibold bg-primary rounded-1"
                          onClick={() => {
                            navigate("/login");
                            setShowSearchSuggestions(false);
                          }}
                        >
                          Sign In
                        </Button>
                        <div className="my-3">
                          <small>Don't have any account?</small>
                          <br />
                          <Link
                            to="/register"
                            className="text-decoration-none "
                          >
                            Register for Varskart
                          </Link>
                        </div>
                        <div className=" w-100 border"></div>
                      </div>
                    )}
                  </div>
                </Nav>
                <Nav className="d-flex mr-0">
                  <span>
                    <Nav.Link className="text-white text-nowrap ">
                      <Cart size={20} color="white" onClick={handleOpenModal} />
                      {cart.cartData.length}
                      {/* {console.log("cart data length", cart)} */}
                    </Nav.Link>
                  </span>
                </Nav>
              </div>
            </div>

            {/* Large Screen View end */}


          </Col>{" "}
        </Row>

        {/* <div className=" mx-2">


        </div> */}

        {/* Small and Medium Screen View end */}
        <CartModal show={showModal} handleClose={handleCloseModal} />
      </Container>
      <Row className="container d-inline d-lg-none ">
        <div className=" ">
          {/* Small and Medium Screen View start */}
          <div className="d-flex align-items-center justify-content-between  position-relative ">
            {/* <div className="d-flex align-items-center  w-100  mx-2 "> */}
            <div
              className="mx-1  d-flex align-items-start"
              onMouseEnter={handleProductMouseEnter}
              onMouseLeave={handleProductMouseLeave}

            >
              <Nav.Link
                as={"Link"}
                to="#"
                className=" fw-semibold text-white text-nowrap "
                style={{ cursor: "pointer" }}
              >
                All Categories
                <img
                  src={dropdown}
                  alt=""
                  style={{ width: "12px", height: "10px" }}
                  className=" mx-1 "
                />
              </Nav.Link>
              {showProductOverlay && (
                  <div className="product-overlay text-start  rounded p-3">
                    <h6 className="fw-bold">All Categories</h6>
                    <div className=" w-100 border"></div>
                    <div className="my-3">
                      <Row>
                        {department?.getAllDepartments?.data &&
                          department?.getAllDepartments?.data.length > 0 ? (
                          department?.getAllDepartments?.data?.map(
                            (departmentDetail, index) => (
                              <Col key={index} md={4} sm={2}>
                                {/* <Link
                                  to={`/categories/${departmentDetail.id}`}
                                  style={{ textDecoration: "none" }}
                                > */}
                                <div
                                  className="product-item my-1 text-start  text-muted"
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate({
                                      pathname: PATH.CATEGORIES_LAYOUT,
                                      search: createSearchParams({
                                        departmentId: departmentDetail.id
                                      }).toString()
                                    });
                                    setShowProductOverlay(false);
                                  }}
                                >
                                  {departmentDetail.deptName}
                                </div>
                                {/* </Link> */}
                              </Col>
                            )
                          )
                        ) : (
                          <Col>
                            <p>No record found</p>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className=" w-100 border"></div>
                  </div>
                )}
            </div>


            {/* </div> */}



            <div className="d-flex  text-end ">
              <div
                className=" d-flex align-items-center "
              // style={{ marginLeft: "auto" }}
              >
                <div className="mx-2">
                  <Nav className="d-flex mr-0">
                    {/* <span> */}
                    <Nav.Link className="text-white text-nowrap ">
                      <Cart size={20} color="white" onClick={handleOpenModal} />
                      {cart.cartData.length}
                    </Nav.Link>
                    {/* </span> */}
                  </Nav>
                </div>
                <div
                  className="mx-1 text-center "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className="rounded-circle bg-white text-center"
                    style={{ cursor: "pointer" }}
                    onClick={handleAccountButtonClick}
                  >
                    {auth?.currentUser === null ? (
                      <PersonFill size={30} className=" text-black" />
                    ) : (
                      <img
                        src={`https://ui-avatars.com/api/?name=${auth?.currentUser?.name}`}
                        width={"100%"}
                      />
                    )}
                  </div>
                  <div className="my-button-container">
                    <Button
                      className="bg-transparent text-center d-none d-lg-inline"
                      id="button-overlay"

                    >
                      {auth?.currentUser?.name || "Account Name"}
                    </Button>
                    {showOverlay && (
                      <div className="overlay2 rounded p-3">
                        <h6 className="fw-bold">Sign In</h6>
                        <p>Your overlay content goes here.</p>
                        {/* <Link 

                        > */}
                          <Button className="w-75 fw-semibold bg-primary rounded-1  "
                            onClick={() => {
                              navigate("/login");
                              setShowSearchSuggestions(false);
                              setShowOverlay(false);
                            }}
                          >
                            Sign In 
                          </Button>
                        {/* </Link> */}
                        <div className="my-3">
                          <Button
                            // to="/register"
                            className="text-decoration-none bg-transparent text-primary "
                            onClick={() => {
                              navigate("/register");
                              setShowOverlay(false);
                            }}
                          >
                            Register for Varskart
                          </Button>
                        </div>
                        <div className=" w-100 border"></div>
                      </div>
                    )}
                  </div>
                </div>

              </div>


              <div className="position-absolute top-0 end-0 w-100">
                {/* <Form.Control
                  type="text"
                  name="expandedSearch"
                  className={`w-100  ${isSearchExpanded ? '' : 'd-none'}`}
                  placeholder="Search by Tags/Categories/Brands/Product"
                  onChange={handleInputChange}
                  onFocus={() => { searchValue?.length > 0 && setShowSearchSuggestions(true) }}
                  onBlur={() => {
                    dispatch(ResetFetchProductsByFilter())
                    // dispatch(ResetGetAllFilteredProducts())
                    dispatch(ResetAllProductsByFilter())}
                    }
                    // onKeyDown={(event) => {
                    //   if (event.key === 'Enter') {
                    //   navigate({
                    //     pathname: PATH.CATEGORIES_LAYOUT,
                    //     search: createSearchParams({
                    //       searchKey: "" + event.target.value
                    //       // categoryId: searchItem?.getAllSearchItems?.data?.category?.[0]?.id
                    //     }).toString()
                    //   });
                    //   }
                    // }}

                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && event.target.value?.length>0) {
                        navigate({
                          pathname: PATH.CATEGORIES_LAYOUT,
                          search: createSearchParams({
                            searchKey: "" + event.target.value
                            // categoryId: searchItem?.getAllSearchItems?.data?.category?.[0]?.id
                          }).toString()
                        });
                        window.scrollTo(0, 0);
                        setSearchValue("")
                        setShowSearchSuggestions(false)
                        // setSearchInputValue("")
                      }
                    }}
                /> */}

                {/* Close button for expanded search */}
                {/* <button
                  className={`bg-danger rounded-0 border-0 position-absolute top-0 end-0 ${isSearchExpanded ? '' : 'd-none'}`}
                  type="button"
                  style={{ height: '100%' }}
                  onClick={() => {
                    setShowSearchSuggestions(false);
                    dispatch(ResetAllProductsByFilter());
                    // dispatch(ResetGetAllFilteredProducts())
                    dispatch(ResetFetchProductsByFilter());
                    document.getElementsByName('expandedSearch')[0].value = '';
                    toggleSearchBar();
                  }}
                >
                  <span >&times;</span>
                </button> */}
              </div>

              {/* <button
                className="bg-transparent rounded-0 border-0"
                type="button"
                // onClick={toggleSearchBar}
                onClick={() => {
                  if (!isSearchExpanded) {
                    toggleSearchBar(); // Execute toggleSearchBar when the regular search input is visible
                  } else {
                    onSearchClick(); // Execute onSearchClick when the expanded search bar is open
                  }
                }}
              >
                <Search size={26} color="white" className="p-1" />
              </button> */}
            </div>
          </div>
        </div>


      </Row>
      <Row className="container d-inline d-lg-none my-2">
      <div className="d-flex align-items-center px-2 w-100 w-sm-auto">
                <Form.Control
                  type="text"
                  name="paymentTypeId"
                  placeholder="Search by Tags/Categories/Brands/Product"
                  onChange={handleInputChange}
                  value={searchInputValue}
                  onFocus={() => { searchValue?.length > 0 && setShowSearchSuggestions(true) }}
                  onBlur={() => {
                    dispatch(ResetFetchProductsByFilter())
                    dispatch(ResetAllProductsByFilter())
                    // dispatch(ResetGetAllFilteredProducts())
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.target.value?.length>0) {
                      navigate({
                        pathname: PATH.CATEGORIES_LAYOUT,
                        search: createSearchParams({
                          searchKey: "" + event.target.value
                          // categoryId: searchItem?.getAllSearchItems?.data?.category?.[0]?.id
                        }).toString()
                      });
                      window.scrollTo(0, 0);
                      setSearchValue("")
                      setShowSearchSuggestions(false)
                      // setSearchInputValue("")
                    }
                  }}
                />
                <button
                  className="bg-danger rounded-0 border-0 py-2"
                  type="button"
                  onClick={onSearchClick}
                >
                  <Search size={26} color="white" className="p-1" />
                </button>
              </div>
      </Row>
    </Navbar>
  );
};

export default HeaderNavbar;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import authService from './auth.service';
import { toast } from 'react-toastify';

// Get currentUser from localStorage
const reactUser = JSON.parse(localStorage.getItem('varskart_customer'));

const initialState = {
  currentUser: reactUser || null,

  isLoginError: false,
  isLoginSuccess: false,
  isLoginLoading: false,
  loginMessage: '',

  googleLoginError: false,
  googleLoginSuccess: false,
  googleLoginLoading: false,
  googleLoginMessage: '',

  isRegisterError: false,
  isRegisterSuccess: false,
  isRegisterLoading: false,
  registerMessage: '',

  isEmailExistError: false,
  isEmailExistSuccess: false,
  isEmailExistLoading: false,
  isEmailExist: null,

  forgotPasswordError: false,
  forgotPasswordSuccess: false,
  forgotPasswordLoading: false,
  forgotPassword: '',

  // getUserByIdSuccess: false,
  // getUserByIdLoading: false,


  // isLoggedIn: false,
};

export const GoogleLogin = createAsyncThunk('google-login', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await authService.googleLogin(data);
    if (response?.data?.httpStatusCode === 200 && response.data.succeeded) {
      if (moveToNext) {
        moveToNext(response?.data?.message, "success");
      }
    } else {

      moveToNext(response?.data?.message, "error");
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const LoginAction = createAsyncThunk('auth/login', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await authService.login(data);
    if (response?.status === 200 && response.data.succeeded ) {
      if (moveToNext) {
        moveToNext("You are successfully logged in", "success", response.data.data);
      }
     
    } else {

      moveToNext(response?.data?.message, "error");
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CheckOtp = createAsyncThunk('auth/checkOtp', async ({ otp, email, moveToNext }, thunkAPI) => {
  try {
    const response = await authService.checkOtp({ otp, email });
      if (response.status === 200 && response.data.succeeded) {
          if (moveToNext) {
              moveToNext(response?.data?.message, "success");
          }
      } else {
          if (moveToNext) {
              moveToNext(response?.data?.message, "error");
          }
      }
      return response;
  } catch (error) {
      if (moveToNext) {
          moveToNext(error?.message, 'error');
      }
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
  }
});


export const CheckEmail = createAsyncThunk('auth/checkEmail', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await authService.checkEmail(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ResetPassword = createAsyncThunk('auth/resetPassword', async ({ data,  moveToNext }, thunkAPI) => {
  try {
    const response = await authService.resetPassword(data);
    if (response.data.succeeded) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Registration currentUser
export const register = createAsyncThunk('auth/register', async ({ values, notifyToaster }, thunkAPI) => {
  try {
    const response =await authService.register(values);
    // return true;
    if (response.succeeded) {
      notifyToaster();
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    // notifyToaster();
    toast.error(error);

    // notifyToaster(error.message || error, false);
    return thunkAPI.rejectWithValue(error);
  }
});


// Registration currentUser
export const Logout = createAsyncThunk('auth/logout', async (moveToNext, thunkAPI) => {
  
  try {
    moveToNext();
    return;
  } catch (error) {
    // notifyToaster(error.message || error, false);
    return thunkAPI.rejectWithValue(error);
  }
});


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isRegisterLoading = true;
        state.isRegisterSuccess = false;
      })
      .addCase(register.fulfilled, (state) => {
        state.isRegisterLoading = false;
        state.isRegisterSuccess = true;
      })
      .addCase(register.rejected, (state) => {
        state.isRegisterLoading = false;
        state.isRegisterSuccess = false;
      })
      .addCase(LoginAction.pending, (state) => {
        state.isLoginLoading = true;
        state.isLoginSuccess = '';
      })
      .addCase(LoginAction.fulfilled, (state, action) => {
        state.isLoginLoading = false;
        state.isLoginSuccess = true;
        state.isLoggedIn = true;
        state.currentUser = action.payload.data.data;
      })
      .addCase(LoginAction.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoginLoading = false;
        state.isLoginError = true;
        state.currentUser = null;
      })
      .addCase(GoogleLogin.pending, (state) => {
        state.isLoginLoading = true;
        state.isLoginSuccess = '';
      })
      .addCase(GoogleLogin.fulfilled, (state, action) => {
        state.isLoginLoading = false;
        state.isLoginSuccess = true;
        state.isLoggedIn = true;
        state.currentUser = action.payload.data.data;
      })
      .addCase(GoogleLogin.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoginLoading = false;
        state.isLoginError = true;
        state.currentUser = null;
      })
      .addCase(CheckEmail.pending, (state) => {
        state.isEmailExistLoading = true;
        state.isEmailExistSuccess = false;
      })
      .addCase(CheckEmail.fulfilled, (state, action) => {
        state.isEmailExistLoading = false;
        state.isEmailExistSuccess = action.payload.data.succeeded;
      })
      .addCase(CheckEmail.rejected, (state) => {
        state.isEmailExistLoading = false;
        state.isEmailExistSuccess = false;
      })
      .addCase(CheckOtp.pending, (state) => {
        state.isOtpExistLoading = true; 
      })
      .addCase(CheckOtp.fulfilled, (state, action) => {
        state.isLoginLoading = false;
        state.isLoginSuccess = true;
        state.isLoggedIn = true;
        state.currentUser = action.payload.data.data;
        // state.isOtpExistLoading = false;
        // state.isOtpExistSuccess = true;
        // state.currentUser = action.payload.data.data;
      })
      .addCase(CheckOtp.rejected, (state) => {
        state.isOtpExistLoading = false;
        state.isOtpExistSuccess = false;
        // state.message = action.payload; 
      })
      .addCase(ResetPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPassword = '';
      })
      .addCase(ResetPassword.fulfilled, (state, action) => {
        state.forgotPasswordSuccess = true;
        state.forgotPasswordLoading = false;
        state.isEmailExistSuccess = false;
        state.forgotPassword = action.payload;
      })
      .addCase(ResetPassword.rejected, (state, action) => {
        state.forgotPassword = action.payload;
        state.forgotPasswordLoading = false;
        state.forgotPasswordError = true;
      })
      .addCase(Logout.fulfilled, (state) => {
        
        localStorage.removeItem("varskart_customer")
        state.currentUser = null;
      });
  
  },
});


export const { reset } = authSlice.actions;

export default authSlice.reducer;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rfqService from "./rfq.services";

const initialState = {
  getAllRFQs: [],
  getAllRFQsLoading: false,

  createRfqLoading: false,
  createRfq: null,

  getRFQByIdLoading: false,
  getRFQById: null,

  createRfqImageLoading: false,
  createRfqImage: null,

  deleteRfqImageLoading: false,
  deleteRfqImage: null,
};

export const GetAllRFQs = createAsyncThunk(
  "rfq/getAll",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await rfqService.getAllRFQs(data);

      if (response.status === 200) {
        if (moveToNext) {
          moveToNext(response?.data, "success");
        }
      } else {
        moveToNext(response?.data?.message, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreateRfq = createAsyncThunk(
  "rfq/create",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await rfqService.createRfqApi(data);
      if (response.status === 200) {
        if (moveToNext) {
          moveToNext(response?.data?.message, "success");
        }
      } else {
        moveToNext(response?.data?.message, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateRfqImage = createAsyncThunk(
  "rfqImage/create",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await rfqService.createRfqImage(data);
      if (response.status === 200) {
        if (moveToNext) {
          moveToNext(response?.data?.message, "success");
        }
      } else {
        moveToNext(response?.data?.message, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetRFQById = createAsyncThunk('rfq/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await rfqService.getRFQById(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteRfqImage = createAsyncThunk(
  "productImage/delete",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      //
      const response = await rfqService.deleteRfqImage(data);

      if (response.status === 200) {
        if (moveToNext) {
          moveToNext(response?.data, "success");
        }
      } else {
        moveToNext(response?.data, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const rfqSlice = createSlice({
  name: "rfqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRFQs.pending, (state) => {
        state.getAllRFQsLoading = true;
      })
      .addCase(GetAllRFQs.fulfilled, (state, action) => {
        state.getAllRFQsLoading = false;
        state.getAllRFQs = action.payload.data;
      })
      .addCase(GetAllRFQs.rejected, (state, action) => {
        state.getAllRFQsLoading = false;
        state.getAllRFQs = action.payload;
      })
      .addCase(GetRFQById.pending, (state, action) => {
        state.getRFQByIdLoading = true;
        state.getRFQById = action.payload;
      })
      .addCase(GetRFQById.fulfilled, (state, action) => {
        state.getRFQByIdLoading = false;
        state.getRFQById = action.payload;
      })
      .addCase(GetRFQById.rejected, (state, action) => {
        state.getRFQByIdLoading = false;
        state.getRFQById = action.payload;
      })
      .addCase(CreateRfq.pending, (state) => {
        state.createRfqLoading = true;
      })
      .addCase(CreateRfq.fulfilled, (state, action) => {
        state.createRfqLoading = false;
        state.createRfq = action.payload;
      })
      .addCase(CreateRfq.rejected, (state, action) => {
        state.createRfqLoading = false;
        state.createRfq = action.payload;
      });
    builder
      .addCase(DeleteRfqImage.pending, (state) => {
        state.deleteRfqImageLoading = true;
      })
      .addCase(DeleteRfqImage.fulfilled, (state, action) => {
        state.deleteRfqImageLoading = false;
        state.deleteRfqImage = action.payload;
      })
      .addCase(DeleteRfqImage.rejected, (state, action) => {
        state.deleteRfqImageLoading = false;
        state.deleteRfqImage = action.payload;
      });
  },
});

export const { reset } = rfqSlice.actions;

export default rfqSlice.reducer;

const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  LANDING: "/",
  REGISTER: "/register",
  LOGIN: "/login",
  DESHBOARD: "/dashboard",
  SUB_CATEGORIES:"/sub-categories",
  PRODUCT_DETAILS:"/product-details/:id",
  CATEGORIES_LAYOUT:"/categories",
  CART :"/cart",
  FORGOTPASSWORD : "/verify-email",
  CHANGEPASSWORD: "/forgot-password",
  RFQ: "/rfq",
  PROCEED_To_CHECKOUT: "/proceed-to-checkout",
  ABOUT_US: "/about_us",
  PRIVACY_POLICY: "/privacy-policy",
  DELIVERY_POLICY: "/delivery-policy",
  TERMS_AND_CONDITION: "/terms-and-conditions",
  BECOME_SUPPLIER: "/become-a-supplier",
  // ORDER_LIST: "/order_list",
  // ORDER_DETAIL: "/order_detail",
  // RFQ_DETAIL: "/rfq_detail",
  // RFQ_LIST: "/rfq_list",
  ADDRESS_DETAIL: "/address-detail",
  BLOGS: "/blogs",
  BLOG_DETAIL: "/blog-detail",
  ORDER_RECEIVED: "/order-received",
  CONTACT_US: "/contact-us",
  MEETING_REQUEST: "/meeting-request",
};

export const calculateVat = (Subtotal,deliveryCharges) => { 
  const vat = 0.05/1.05 * (Subtotal + deliveryCharges)
  return vat.toFixed(2)
}

export { ERROR, PATH };

import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "../../assets/css/services.css";
import { Link } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";

const ServicesList = ({ cardData, index, links }) => {
  return (
    <div className="servicesList d-flex flex-column h-100 p-4 ">
      <div>
        <Row className="d-flex flex-wrap">
          <Col md={12} lg={8}>
            <div>
              <h5 className="card-title">{cardData.heading}</h5>
              <p className="mobile-view-text"
                style={{ 
                // textAlign: 'justify', 
                margin: 0, 
                lineHeight: 1.4, 
                // wordBreak: 'break-all' ,
                // wordWrap: 'break-word' 
              }}
              >
                <ReactReadMoreReadLess
                charLimit={100}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
            >
                {cardData.text}
            </ReactReadMoreReadLess>
                
              </p>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <div className="mb-2">
              <Image
                src={cardData.image}
                alt="Your Image"
                className=""
                fluid
                style={{ maxwidth: '100%', height: 'auto' }}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="mt-auto d-flex flex-wrap">
        {/* Add first three links in the first row */}
        {/* {links.slice(0, 3).map((link, index) => (
          <div key={index} className="mx-1 mr-2">
            <Link to={link.url} className="text-decoration-none text-black ">
              {link.label}
            </Link>
          </div>
        ))} */}
      </div>

      <div className="d-flex flex-wrap">
        {/* Add remaining links in the next row */}
        {/* {links.slice(3).map((link, index) => (
          <div key={index} className="mx-1">
            <Link to={link.url} className="text-decoration-none text-black">
              {link.label}
            </Link>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default ServicesList;

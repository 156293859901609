/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';


const getAllProducts = async (req) => {

    const data = await axios.post(`${baseUrl}ItemEntry/getAll`, req);

    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data.message;
    }
    return data;
};
const getAllFilteredProducts = async (req) => {
    const data = await axios.post(`${baseUrl}ItemEntry/getAll`, req);
    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data.message;
    }
    return data;
};

const getAllProductsByFilter = async (req) => {
    const data = await axios.post(`${baseUrl}ItemEntry/getAllByTag`, req);
    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data.message;
    }
    return data;
};

const getAllSearchFilters = async (req) => {
    const data = await axios.post(`${baseUrl}Filters/AllFilters`, req);
    // if (data?.data?.succeeded) {
    //     // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    // } else {
    //     throw data.message;
    // }
    return data;
};
const fetchProductsByFilter = async (req) => {

    const data = await axios.post(`${baseUrl}ItemEntry/getAllByTag`, req);

    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data.message;
    }
    return data;
};


const getProductById = async (id) => {
    // let baseUrl = process.env.API_ENDPOINT

    // const data = await api.post("/ItemEntry/login", req);
    // const data = await axios.post(`${baseUrl}/ItemEntry/login`, req);
    const data = await axios.get(`${baseUrl}ItemEntry/get?ItemId=${id}`);
    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data.message;
    }
    return data;
};


const productService = {
    getAllProductsByFilter,
    getAllProducts,
    getProductById,
    getAllFilteredProducts,
    fetchProductsByFilter,
    getAllSearchFilters
};

export default productService;

import React from "react";
import styled from "styled-components";
import whatsapplogo from "../../assets/svg/whatsapp.svg"

const GoToWhatsapp = () => {
  // Function to initiate WhatsApp call
  const initiateWhatsAppCall = () => {
    // Replace '1234567890' with the desired phone number
    window.open("https://wa.me/+971566031925/?text=Hello%20there!", "_blank");
  };

  return (
    <Wrapper>
      <div className="top-btn" onClick={initiateWhatsAppCall}>
        <img src={whatsapplogo} alt="whatsapp" className="top-btn--icon" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  z-index: 999;

  .top-btn {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: filter 0.3s ease; 

    &:hover {
      filter: brightness(0.8); 
    }

    &--icon {
      width: 50px;
      height: 50px;
      animation: gototop 1.6s linear infinite alternate-reverse;
    }

    @keyframes gototop {
      0% {
        transform: translateY(-0.5rem);
      }
      100% {
        transform: translateY(0.5rem);
      }
    }
  }

  @media (max-width: 768px) {
    .top-btn {
      bottom: 1rem;
      right: 0;
      width: 0.3rem;
      height: 0.3rem;

      &--icon {
        right: 0;

        width: 40px;
        height: 40px;
      }
    }
  }
`;

export default GoToWhatsapp;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import brandService from './brand.service';

const initialState = {
  getAllBrands: [],
  getAllBrandLoading: false,

  createBrandLoading: false,
  createBrand: null,

  getBrandByIdLoading: false,
  getBrandById: null,

  deleteBrandLoading: false,
  deleteBrand: null,

  updateBrandLoading: false,
  updateBrand: null,

  getAllBrandCategories: [],
  getAllBrandCategoryLoading: false,

  createBrandCategoryLoading: false,
  createBrandCategory: null,

  getBrandCategoryByIdLoading: false,
  getBrandCategoryById: null,

  deleteBrandCategoryLoading: false,
  deleteBrandCategory: null,

  updateBrandCategoryLoading: false,
  updateBrandCategory: null,
};

export const GetAllBrands = createAsyncThunk('brand/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.getAllBrands(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateBrand = createAsyncThunk('brand/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.createBrand(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateBrand = createAsyncThunk('brand/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.updateBrand(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetBrandById = createAsyncThunk('brand/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.getAllBrands(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteBrand = createAsyncThunk('brand/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.deleteBrand(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllBrandCategories = createAsyncThunk('brand/category/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.getAllBrandCategories(data);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateBrandCategory = createAsyncThunk('brand/category/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.createBrandCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateBrandCategory = createAsyncThunk('brand/category/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.updateBrandCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetBrandCategoryById = createAsyncThunk('brand/category/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.getAllBrandCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteBrandCategory = createAsyncThunk('brand/category/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await brandService.deleteBrandCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBrands.pending, (state) => {
        state.getAllBrandLoading = true;
      })
      .addCase(GetAllBrands.fulfilled, (state, action) => {
        state.getAllBrandLoading = false;
        state.getAllBrands = action.payload.data;
      })
      .addCase(GetAllBrands.rejected, (state, action) => {
        state.getAllBrandLoading = false;
        state.getAllBrands = action.payload.data;
      })
      .addCase(CreateBrand.pending, (state) => {
        state.createBrandLoading = true;
      })
      .addCase(CreateBrand.fulfilled, (state, action) => {
        state.createBrandLoading = false;
        state.createBrand = action.payload;
      })
      .addCase(CreateBrand.rejected, (state, action) => {
        state.createBrandLoading = false;
        state.createBrand = action.payload;
      })
      .addCase(UpdateBrand.pending, (state) => {
        state.updateBrandLoading = true;
      })
      .addCase(UpdateBrand.fulfilled, (state, action) => {
        state.updateBrandLoading = false;
        state.updateBrand = action.payload;
      })
      .addCase(UpdateBrand.rejected, (state, action) => {
        state.updateBrandLoading = false;
        state.updateBrand = action.payload;
      })
      .addCase(GetBrandById.pending, (state, action) => {
        state.getBrandByIdLoading = true;
        state.getBrandById = action.payload;
      })
      .addCase(GetBrandById.fulfilled, (state, action) => {
        state.getBrandByIdLoading = false;
        state.getBrandById = action.payload;
      })
      .addCase(GetBrandById.rejected, (state, action) => {
        state.getBrandByIdLoading = false;
        state.getBrandById = action.payload;
      })
      .addCase(DeleteBrand.pending, (state) => {
        state.deleteBrandLoading = true;
      })
      .addCase(DeleteBrand.fulfilled, (state, action) => {
        state.deleteBrandLoading = false;
        state.deleteBrand = action.payload;
      })
      .addCase(DeleteBrand.rejected, (state, action) => {
        state.deleteBrandLoading = false;
        state.deleteBrand = action.payload;
      })
      .addCase(GetAllBrandCategories.pending, (state) => {
        state.getAllBrandCategoryLoading = true;
      })
      .addCase(GetAllBrandCategories.fulfilled, (state, action) => {
        state.getAllBrandCategoryLoading = false;
        state.getAllBrandCategories = action.payload.data;
      })
      .addCase(GetAllBrandCategories.rejected, (state, action) => {
        state.getAllBrandCategoryLoading = false;
        state.getAllBrandCategories = action.payload;
      })
      .addCase(CreateBrandCategory.pending, (state) => {
        state.createBrandCategoryLoading = true;
      })
      .addCase(CreateBrandCategory.fulfilled, (state, action) => {
        state.createBrandCategoryLoading = false;
        state.createBrandCategory = action.payload;
      })
      .addCase(CreateBrandCategory.rejected, (state, action) => {
        state.createBrandCategoryLoading = false;
        state.createBrandCategory = action.payload;
      })
      .addCase(UpdateBrandCategory.pending, (state) => {
        state.updateBrandCategoryLoading = true;
      })
      .addCase(UpdateBrandCategory.fulfilled, (state, action) => {
        state.updateBrandCategoryLoading = false;
        state.updateBrandCategory = action.payload;
      })
      .addCase(UpdateBrandCategory.rejected, (state, action) => {
        state.updateBrandCategoryLoading = false;
        state.updateBrandCategory = action.payload;
      })
      .addCase(GetBrandCategoryById.pending, (state, action) => {
        state.getBrandCategoryByIdLoading = true;
        state.getBrandCategoryById = action.payload;
      })
      .addCase(GetBrandCategoryById.fulfilled, (state, action) => {
        state.getBrandCategoryByIdLoading = false;
        state.getBrandCategoryById = action.payload;
      })
      .addCase(GetBrandCategoryById.rejected, (state, action) => {
        state.getBrandCategoryByIdLoading = false;
        state.getBrandCategoryById = action.payload;
      })
      .addCase(DeleteBrandCategory.pending, (state) => {
        state.deleteBrandCategoryLoading = true;
      })
      .addCase(DeleteBrandCategory.fulfilled, (state, action) => {
        state.deleteBrandCategoryLoading = false;
        state.deleteBrandCategory = action.payload;
      })
      .addCase(DeleteBrandCategory.rejected, (state, action) => {
        state.deleteBrandCategoryLoading = false;
        state.deleteBrandCategory = action.payload;
      });
  },
});

export const { reset } = brandSlice.actions;

export default brandSlice.reducer;

/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";



const getUserById = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT
  // const data = await api.post("/Users/login", id);
  // const data = await axios.post(`${baseUrl}/Users/login`, id);
  const data = await axios.get(`${baseUrl}Users/get?userId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createContact = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Contact/login", req);
  // const data = await axios.post(`${baseUrl}/Contact/login`, req);
  const data = await axios.post(`${baseUrl}ContactUs/SendEmail`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};
// const contactUs = async (res) => {
//   let config = {
//     headers: {
      
//       Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
//       'Content-Type': 'application/json',
//       Accept : 'application/json',
//     }
//   }
//   try {

//     const { data } = await axios.post(`http://glareapis.growcrmpro.com/api/ContactUs/SendEmail`, res , config);

//     if (data?.succeeded) {
//       return data.data;
//     }
//      else {
//       throw data.message;
//     }
//   } catch (error) {
//     console.error(error); 
//     throw error.message || "An error occurred during registration 0000";
//   }
// };
const userService = {
  getUserById,
  // contactUs,
  createContact,
 
};

export default userService;

import React from "react";
import ServicesList from "./ServicesList";
import { Col, Container, Row } from "react-bootstrap";
import Icon_1 from "../../assets/images/Services.png";
import Icon_2 from "../../assets/images/Project Business.png";
import Icon_3 from "../../assets/images/MMRO Businesses.png";
import Icon_4 from "../../assets/images/SERVICES OFFERED.png";
import Icon_5 from "../../assets/images/ESG FOCUS.png";
import Icon_6 from "../../assets/images/QUALITY MANAGEMENT.png";
import Icon_7 from "../../assets/images/VOLUME AGGREGATOR.png";

import "../../assets/css/services.css";

import SubServices from "./SubServices";

const Services = () => {
  const cardData = [
    {
      heading: "MMRO Businesses",
      text: `We support manufacturing, maintenance, repair and operations for 
      various industries by providing them support to source raw materials, tools,
       components, parts and finished goods. We take care of complete direct and
        indirect material sourcing requirements of MMRO business entities.
      `,
      image: Icon_3,
    },
    {
      heading: "Project Business",
      text: `Our dedicated and well experience project sourcing team supports projects 
      businesses  complying with their cost constraints, technical compliance and 
      delivery timelines. We also support them by making supplier contracts to safeguard 
      against price fluctuations.`,
      image: Icon_2,
    },
    {
      heading: "Services",
      text: `Along with direct and indirect material supply we provide sourcing support 
      for services such as installation, maintenance and commissioning.`,
      image: Icon_1,
    },

  ];
  const cardLinks = [
    [{ label: "People", url: "/link1" }, { label: "Operations", url: "/link2" }, { label: "Safety and Health", url: "/link3" }],
    [{ label: "Emergency Preparedness", url: "/link1" }, { label: "Industries", url: "/link2" }, { label: "Metalworking", url: "/link3" }, { label: "Safety and Health Solutions Center", url: "/link4" }],
    [{ label: "KeepStock Inventory Management", url: "/link1" }, { label: "Managed Safety Programs", url: "/link2" }, { label: "Energy", url: "/link3" }, { label: "EH&S", url: "/link4" }, { label: "Facility", url: "/link5" }],
  ];
  const dataArray = [
    {
      heading: "SERVICES OFFERED",
      text: `We support businesses to submit RFQs for multiple categories, 
      helps to standardize and streamline the quotation process. We lead 
      negotiation with suppliers, mutually agree on INCOTERMS and contractual 
      terms. This helps businesses to reduce their overall procurement lead 
      time and source the best value. Ensures product quality through pre 
      vetted suppliers and product inspection services. Also, continuously 
      strives to improve and innovate our platform and services to meet the 
      evolving needs of businesses and suppliers.`,
      image: Icon_4,
    },
    {
      heading: "ESG FOCUS",
      text: `It refers to the practice of integrating environmental, sustainability 
      and governance factors into the procurement process. Varskart's ESG sourcing 
      solutions  help businesses identify suppliers who align with ESG criteria, 
      such as sustainability practices, ethical labor standards, diversity and 
      inclusion policies, and corporate governance principles. By prioritizing 
      ESG considerations in supplier selection and management, businesses can 
      mitigate risks, enhance their reputation, and contribute to positive social 
      and environmental impacts throughout their supply chain.`,
      image: Icon_5,
    },
    {
      heading: "QUALITY MANAGEMENT",
      text: `Varskart's sourcing solutions also address quality by implementing 
      rigorous supplier quality management processes. It involves implementing 
      quality assurance measures such as supplier audits, product inspections and 
      performance tracking to ensure that sourced products meet the required quality 
      standards. By proactively managing quality throughout the sourcing process, Varskart 
      helps businesses to minimize quality rejections, reduce product defects, and maintain 
      consistency on their supply chain operations.`,
      image: Icon_6,
    },
    {
      heading: "VOLUME AGGREGATOR",
      text: `Varskart helps businesses consolidate their purchasing volume across multiple 
      suppliers to achieve better pricing, terms, and overall value. This involves negotiating 
      bulk discounts, leveraging economies of scale and centralized procurement activities to 
      streamline operations and reduce costs. By aggregating purchasing volumes  businesses can 
      optimize their sourcing strategy, reduce costs and maximize their profitability.`,
      image: Icon_7,
    },
  ];
  return (
    <Container className="">
      <div>
        <Row>
          <Col xs={12}>
            <h5 className="card-title mb-3">
              Smart Sourcing solutions for every industry
            </h5>
          </Col>
        </Row>
      </div>

      <Row className="mb-5">
        {cardData.map((card, index) => (
          <Col key={index} xs={12} sm={6} md={4} className=" px-0">
            <ServicesList cardData={card} links={cardLinks[index]} />
          </Col>
        ))}
      </Row>
      <div className="mt-5">
        <Row className="mt-5 mb-5">
          {dataArray.map((data, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3 ">
              <SubServices cardData={data} />
            </Col>
          ))}
        </Row>
        <Container
          className="serviceBottom rounded "
        // style={{
        //   backgroundColor: "white",
        //   boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
        //   padding:"16px",
        // }}
        >

        </Container>
      </div>
    </Container>
  );
};

export default Services;

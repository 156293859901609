import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllPolicy = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);

  const data = await axios.post(`${baseUrl}Static/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const getPolicyById = async (id) => {
  // 
  const data = await axios.get(`${baseUrl}Static/get?StaticId=${id}`);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};



const policyService = {
  getAllPolicy,
  getPolicyById,
 
};

export default policyService;

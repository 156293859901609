import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteProductFromCart,
  AddUpdateProductToCart,
} from "../../redux/cart/cart.slice";
import { NO_IMAGE_FOUND } from "../../config";
import VarskartLogo from "../../assets/images/logo512.jpg"


const ProductDetail = ({ item }) => {
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item.quantity);
  const handleQuantityChange = (item, quantity) => {
    setQuantity(quantity)

    const updatedItem = { ...item, quantity,replaceQuantity:true };
    dispatch(AddUpdateProductToCart({ data: updatedItem }));
  };
  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  return (
    <Row key={item.productId}>
      <Col xl="6" md="4">
        <img
          width={40}
          className="rounded"
          src={item?.productImageUrl || NO_IMAGE_FOUND}
          alt="Product Image"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop in case fallback also fails
            e.target.src = VarskartLogo;
          }}
        />
        <b className="ms-2">{item.productName}</b>
      </Col>
      <Col xl="6" md="8">
        <Row sm="12">
          <Col md="4" className="text-center">
            <input
              type="number"
              style={{ width: "70px", height: "auto" }}
              placeholder="Qty"
              value={quantity}
              min={1}
              onChangeCapture={(e) =>
                handleQuantityChange(item,+e.target.value)
              }
            />
          </Col>
          <Col md="4" className="text-center">
            <small>AED </small>
            {item.salePrice * item.quantity}
          </Col>
          <Col md="4" className="text-center">
            <p
              className="text-decoration-none text-success"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  DeleteProductFromCart({
                    data: cart?.cartData?.filter(
                      (finalItem) => +finalItem.productId !== +item.productId
                    ),
                  })
                );
              }}
            >
              Remove
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductDetail;

import { combineReducers } from "redux";
// import { userReducer } from "./userReducer";
import brandReducer from "../brand/brand.slice";
import dynamicFieldsReducer from "../dynamicFields/dynamicFields.slice";
import departmentReducer from '../department/department.slice';
import productReducer from '../product/product.slice';
import searchItemReducer from '../searchItem/searchItem.slice';
import cartReducer from '../cart/cart.slice';
import authSlice from '../auth/auth.slice';
import rfqReducer from "../rfq/rfq.slice";
import orderReducer from "../order/order.slice";
import addressReducer from "../address/address.slice";
import couponReducer from "../coupon/coupon.slice";
import bannerReducer from "../banner/banner.slice";
import policyReducer from "../policy/policy.slice";
import bucketReducer from "../bucket/bucket.slice";
import userReducer from "../users/user.slice";
import meetingReducer from "../requestMeeting/meeting.slice";
import metasReducer from "../metaTags/meta.slice";

export const rootReducer = combineReducers({
  dynamicField: dynamicFieldsReducer,
  brand: brandReducer,
  order: orderReducer,
  address: addressReducer,
  coupon: couponReducer,
  cart: cartReducer,
  product: productReducer,
  searchItem: searchItemReducer,
  banner: bannerReducer,
  createRfq: rfqReducer,
  department: departmentReducer,
  policy: policyReducer,
  auth: authSlice,
  bucket: bucketReducer,
  user: userReducer,
  meeting: meetingReducer,
  metas: metasReducer,


});

import { ScaleLoader } from "react-spinners";
import "../assets/css/screenLoader.css";

export default function FullScreenLoader() {
  return (
    <div
      className="loaderDiv d-flex justify-content-center align-items-center "
      style={{ padding: "20rem" , minHeight: "100vh",  }}
    >
      <div className="spinner" >
        <ScaleLoader
          color="#313251"
          // loading="true"
          loading={true} 
          // cssOverride={override}
          size={150}
        />
      </div>
    </div>
  );
}

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bannerService from './banner.service';

const initialState = {
    getAllBanner: [],
    getAllBannerLoading: false,


};

export const GetAllBanners = createAsyncThunk('banner/getAll', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await bannerService.getAllBanner(data);

        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {        
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const BannerSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        // isLoading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllBanners.pending, (state) => {
                state.getAllBannerLoading = true;
            })
            .addCase(GetAllBanners.fulfilled, (state, action) => {
                state.getAllBannerLoading = false;
                state.getAllBanner = action.payload.data;
            })
            .addCase(GetAllBanners.rejected, (state, action) => {
                state.getAllBannerLoading = false;
                state.getAllBanner = action.payload;
            })
            
    },
});

export const { reset } = BannerSlice.actions;

export default BannerSlice.reducer;
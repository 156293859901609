import axios from "axios";
import { baseUrl } from "../../config";

const login = async (req) => {
  const data = await axios.post(`${baseUrl}Login/login`, req);
  if (data?.data?.succeeded) {
    if (data?.data?.data?.isotpVerified) {
      localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
      // console.log("register");
    }else {
      throw new Error("Verify OTP")

    }
  }
  return data;
};
const googleLogin = async (req) => {
  let data = await axios.post(`${baseUrl}Login/google-login?Roleid=5`, req, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (data?.data?.succeeded) {
    localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  }
  return data;
};

// const getUserById = async (req) => {

//   const data = await axios.get(`${baseUrl}Users/get?userId=${id}`,  req);
//   if (data?.data?.succeeded) {

//     // const userData = response?.data?.data;
//     // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
//   }
//    else {
//     throw data.message;
//   }
//   return data;
// };

const resetPassword = async (req) => {
  const data = await axios.post(
    `${baseUrl}Login/UpdatePassword?Email=${req.email}&Password=${req.password}`
  );
  if (data?.data?.succeeded) {
  }
  // else {
  //   throw data.message;
  // }
  return data;
};

const checkEmail = async (req) => {
  const data = await axios.post(
    `${baseUrl}Login/CheckEmail?Email=${req.email}`
  );
  if (data?.data?.succeeded) {
  }
  //  else {
  //   throw data.message;
  // }
  return data;
};

// Register
const register = async (req) => {
  try {
    const { data } = await axios.post(`${baseUrl}Users/create`, req);
    if (data?.succeeded && data.httpStatusCode === 201) {
      // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));

      return data;
    } else {
      throw data.message;
    }
    return data;
  } catch (error) {
    console.error(error);
    throw error || "An error occurred during registration 0000";
  }
};
const checkOtp = async (req) => {
  const data = await axios.post(
    `${baseUrl}Login/CheckOTP
    `,req
  );
  if (data?.data?.succeeded) {
    localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  }else {
    throw data.message;
  }
  return data;
};
const authService = {
  login,
  resetPassword,
  checkEmail,
  register,
  googleLogin,
  checkOtp
  // getUserById,
};

export default authService;

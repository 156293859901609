import axios from 'axios';
import { baseUrl } from '../../config';

// const BASE_URL = 'http://glareapis.growcrmpro.com'; // Update with your actual base URL
const createRfqApi = async (rfqData) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
      }
    }

    const response = await axios.post(`${baseUrl}RFQ/create`, rfqData , config);
    if (response?.data?.succeeded) {
      // Handle any specific logic for a successful response
    } else {
      throw response?.data?.message || 'An error occurred during RFQ creation';
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error.message || 'An error occurred during RFQ creation';
  }
};
const createRfqImage = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Images/login", req);
  // const data = await axios.post(`${baseUrl}/Images/login`, req);
  const data = await axios.post(`${baseUrl}RFQImages/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setProductImage("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteRfqImage = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT
  // const data = await api.post("/Images/login", id);
  // const data = await axios.post(`${baseUrl}/Images/login`, id);
  // 
  const data = await axios.delete(`${baseUrl}RFQImages/delete?ImageId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setProductImage("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getAllRFQs = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/RFQ/login", req);
  // const data = await axios.post(`${baseUrl}/RFQ/login`, req);
  const data = await axios.post(`${baseUrl}RFQ/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};
const getRFQById = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/RFQ/login", req);
  // const data = await axios.post(`${baseUrl}/RFQ/login`, req);
  const data = await axios.get(`${baseUrl}RFQ/get?RFQId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};



const rfqService = {
  createRfqApi,
  deleteRfqImage,
  createRfqImage,
  getAllRFQs,
  getRFQById,
};

export default rfqService;


/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import policyService from './policy.service';

const initialState = {
  getAllPolicy: [],
  getAllPolicyLoading: false,

  getPolicyByIdLoading: false,
  getPolicyById: null,

};

export const GetAllPolicy = createAsyncThunk('policy/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await policyService.getAllPolicy(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetPolicyById = createAsyncThunk('policy/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    // 
    const response = await policyService.getPolicyById(data);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllPolicy.pending, (state) => {
        state.getAllPolicyLoading = true;
      })
      .addCase(GetAllPolicy.fulfilled, (state, action) => {
        state.getAllPolicyLoading = false;
        state.getAllPolicy = action.payload.data;

      })
      .addCase(GetAllPolicy.rejected, (state, action) => {
        state.getAllPolicyLoading = false;
        state.getAllPolicy = action.payload;
      })
      .addCase(GetPolicyById.pending, (state, action) => {
        state.getPolicyByIdLoading = true;
        state.getPolicyById = action.payload;
      })
      .addCase(GetPolicyById.fulfilled, (state, action) => {
        state.getPolicyByIdLoading = false;
        state.getPolicyById = action.payload.data;
      })
      .addCase(GetPolicyById.rejected, (state, action) => {
        state.getPolicyByIdLoading = false;
        state.getPolicyById = action.payload;
      })

  },
});

export const { reset } = policySlice.actions;

export default policySlice.reducer;

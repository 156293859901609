/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';


const getAllBanner = async (req) => {
    // let baseUrl = process.env.API_ENDPOINT

    // const data = await api.post("/ItemEntry/login", req);
    // const data = await axios.post(`${baseUrl}/ItemEntry/login`, req);

    const data = await axios.post(`${baseUrl}Banner/getAll`, req);

    if (data?.data?.succeeded) {
        // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
    } else {
        throw data?.message;
    }
    return data;
};




const bannerService = {

    getAllBanner,
};

export default bannerService;

import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllOrders = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", req);
  // const data = await axios.post(`${baseUrl}/Order/login`, req);

  const data = await axios.post(`${baseUrl}Order/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const createOrder = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", req);
  // const data = await axios.post(`${baseUrl}/Order/login`, req);
  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
    }
  }
  const data = await axios.post(`${baseUrl}Order/create`, req, config);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};


const createPayment = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", req);
  // const data = await axios.post(`${baseUrl}/Order/login`, req);
  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
    }
  }
  const data = await axios.post(`${baseUrl}Payment/create`, req, config);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};


const getOrderById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", req);
  // const data = await axios.post(`${baseUrl}/Order/login`, req);
  const data = await axios.get(`${baseUrl}Order/get?OrderId=${req}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const updateOrder = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", req);
  // const data = await axios.post(`${baseUrl}/Order/login`, req);
  const data = await axios.put(`${baseUrl}Order/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const deleteOrder = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", id);
  // const data = await axios.post(`${baseUrl}/Order/login`, id);
  const data = await axios.delete(`${baseUrl}Order/delete?OrderId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};


const orderPaymentStatus = async (orderId) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Order/login", orderId);
  // const data = await axios.post(`${baseUrl}/Order/login`, orderId);
  const data = await axios.post(`${baseUrl}Order/PaymentStatusCheck?OrderId=${orderId}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};



const orderService = {
  getAllOrders,
  getOrderById,
  updateOrder,
  deleteOrder,
  createOrder,
  orderPaymentStatus,

  createPayment
};

export default orderService;

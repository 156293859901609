/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderService from './order.service';

const initialState = {
  getAllOrders: [],
  getAllOrdersLoading: false,

  createOrderLoading: false,
  createOrder: null,

  getOrderByIdLoading: false,
  getOrderById: null,

  deleteOrderLoading: false,
  deleteOrder: null,

  updateOrderLoading: false,
  updateOrder: null,
};

export const GetAllOrders = createAsyncThunk('order/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.getAllOrders(data);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateOrder = createAsyncThunk('order/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.createOrder(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateOrder = createAsyncThunk('order/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.updateOrder(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetOrderById = createAsyncThunk('order/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.getOrderById(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteOrder = createAsyncThunk('order/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.deleteOrder(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OrderPaymentStatus = createAsyncThunk('order/orderPaymentStatus', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.orderPaymentStatus(data);
    
    if (response.data.succeeded) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const CreatePayment = createAsyncThunk('payment/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await orderService.createPayment(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllOrders.pending, (state) => {
        state.getAllOrdersLoading = true;
      })
      .addCase(GetAllOrders.fulfilled, (state, action) => {
        state.getAllOrdersLoading = false;
        state.getAllOrders = action.payload.data;
      })
      .addCase(GetAllOrders.rejected, (state, action) => {
        state.getAllOrdersLoading = false;
        state.getAllOrders = action.payload;
      })
      .addCase(CreateOrder.pending, (state) => {
        state.createOrderLoading = true;
      })
      .addCase(CreateOrder.fulfilled, (state, action) => {
        state.createOrderLoading = false;
        state.createOrder = action.payload;
        state.cartData = [];
      })
      .addCase(CreateOrder.rejected, (state, action) => {
        state.createOrderLoading = false;
        state.createOrder = action.payload;
      })
      .addCase(UpdateOrder.pending, (state) => {
        state.updateOrderLoading = true;
      })
      .addCase(UpdateOrder.fulfilled, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrder = action.payload;
      })
      .addCase(UpdateOrder.rejected, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrder = action.payload;
      })
      .addCase(GetOrderById.pending, (state, action) => {
        state.getOrderByIdLoading = true;
        state.getOrderById = action.payload;
      })
      .addCase(GetOrderById.fulfilled, (state, action) => {
        state.getOrderByIdLoading = false;
        state.getOrderById = action.payload;
      })
      .addCase(GetOrderById.rejected, (state, action) => {
        state.getOrderByIdLoading = false;
        state.getOrderById = action.payload;
      })
      .addCase(DeleteOrder.pending, (state) => {
        state.deleteOrderLoading = true;
      })
      .addCase(DeleteOrder.fulfilled, (state, action) => {
        state.deleteOrderLoading = false;
        state.deleteOrder = action.payload;
      })
      .addCase(DeleteOrder.rejected, (state, action) => {
        state.deleteOrderLoading = false;
        state.deleteOrder = action.payload;
      })
      .addCase(CreatePayment.pending, (state) => {
        state.createPaymentLoading = true;
      })
      .addCase(CreatePayment.fulfilled, (state, action) => {
        
        state.createPaymentLoading = false;
        state.createPayment = action.payload;
      })
      .addCase(CreatePayment.rejected, (state, action) => {
        state.createPaymentLoading = false;
        state.createPayment = action.payload;
      })
      .addCase(OrderPaymentStatus.pending, (state) => {
        state.orderPaymentStatusLoading = true;
      })
      .addCase(OrderPaymentStatus.fulfilled, (state, action) => {

        state.orderPaymentStatusLoading = false;
        state.orderPaymentStatus = action.payload;
      })
      .addCase(OrderPaymentStatus.rejected, (state, action) => {
        state.orderPaymentStatusLoading = false;
        state.orderPaymentStatus = action.payload;
      })

      ;
  },
});

export const { reset } = orderSlice.actions;

export default orderSlice.reducer;

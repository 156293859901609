/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import meetingService from "./meeting.service";

const initialState = {
    createMeetingLoading: false,
    createMeeting: null,

};


export const CreateMeeting = createAsyncThunk(
  "meetings/create",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await meetingService.createMeeting(data);
      if (response.status === 200) {
        if (moveToNext) {
          moveToNext(response?.data?.message, "success");
        }
      } else {
        moveToNext(response?.data?.message, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const meetingSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateMeeting.pending, (state) => {
        state.createMeetingLoading = true;
      })
      .addCase(CreateMeeting.fulfilled, (state, action) => {
        state.createMeetingLoading = false;
        state.createMeeting = action.payload;
      })
      .addCase(CreateMeeting.rejected, (state, action) => {
        state.createMeetingLoading = false;
        state.createMeeting = action.payload;
      });
   
  },
});

export const { reset } = meetingSlice.actions;

export default meetingSlice.reducer;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import uploadFileService from './bucket.service';

const initialState = {
  getAllFilesAsync: [],
  getAllFilesAsyncLoading: false,

  uploadFileLoading: false,
  uploadFile: null,

  getFileByKeyLoading: false,
  getFileByKey: null,

  deleteCouponLoading: false,
  deleteCoupon: null,

  updateCouponLoading: false,
  updateCoupon: null,
};

export const GetAllFilesAsync = createAsyncThunk('getAllFilesAsync/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await uploadFileService.getAllFilesAsync(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UploadFile = createAsyncThunk('uploadFile/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await uploadFileService.uploadFile(data);

    if (response.status === 200) {
      if (moveToNext) {
        
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateCoupon = createAsyncThunk('uploadFile/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await uploadFileService.updateCoupon(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetFileByKeyAsync = createAsyncThunk('uploadFile/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await uploadFileService.getFileByKeyAsync(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteCoupon = createAsyncThunk('uploadFile/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await uploadFileService.deleteCoupon(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bucketReducer = createSlice({
  name: 'Files',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllFilesAsync.pending, (state) => {
        state.getAllFilesAsyncLoading = true;
      })
      .addCase(GetAllFilesAsync.fulfilled, (state, action) => {
        state.getAllFilesAsyncLoading = false;
        state.getAllFilesAsync = action.payload.data;
      })
      .addCase(GetAllFilesAsync.rejected, (state, action) => {
        state.getAllFilesAsyncLoading = false;
        state.getAllFilesAsync = action.payload;
      })
      .addCase(UploadFile.pending, (state) => {
        state.uploadFileLoading = true;
      })
      .addCase(UploadFile.fulfilled, (state, action) => {
        state.uploadFileLoading = false;
        state.uploadFile = action.payload;
      })
      .addCase(UploadFile.rejected, (state, action) => {
        state.uploadFileLoading = false;
        state.uploadFile = action.payload;
      })
      .addCase(UpdateCoupon.pending, (state) => {
        state.updateCouponLoading = true;
      })
      .addCase(UpdateCoupon.fulfilled, (state, action) => {
        state.updateCouponLoading = false;
        state.updateCoupon = action.payload;
      })
      .addCase(UpdateCoupon.rejected, (state, action) => {
        state.updateCouponLoading = false;
        state.updateCoupon = action.payload;
      })
      .addCase(GetFileByKeyAsync.pending, (state, action) => {
        state.getCouponByIdLoading = true;
        state.getFileByKey = action.payload;
      })
      .addCase(GetFileByKeyAsync.fulfilled, (state, action) => {
        state.getCouponByIdLoading = false;
        state.getFileByKey = action.payload;
      })
      .addCase(GetFileByKeyAsync.rejected, (state, action) => {
        state.getCouponByIdLoading = false;
        state.getFileByKey = action.payload;
      })
      .addCase(DeleteCoupon.pending, (state) => {
        state.deleteCouponLoading = true;
      })
      .addCase(DeleteCoupon.fulfilled, (state, action) => {
        state.deleteCouponLoading = false;
        state.deleteCoupon = action.payload;
      })
      .addCase(DeleteCoupon.rejected, (state, action) => {
        state.deleteCouponLoading = false;
        state.deleteCoupon = action.payload;
      });
  },
});

export const { reset } = bucketReducer.actions;

export default bucketReducer.reducer;

import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteProductFromCart, AddUpdateProductToCart } from "../../redux/cart/cart.slice";
import { NO_IMAGE_FOUND } from "../../config";
import ProductDetail from "./ProductDetail";

const CartModal = ({ show, handleClose }) => {
  const cart = useSelector((state) => state.cart);

  return (
    <Modal show={show} onHide={handleClose}  backdrop="static" size="lg">
      <Modal.Header className="custom-modal-header my-0 mt-2 py-0 ">
        <Modal.Title>Cart</Modal.Title>
        <div
          className="custom-close-btn text-end bg-primary p-1 m-2 rounded-circle"
          onClick={handleClose}
        >
          <button className="border-0 bg-transparent text-white fw-bold">
            X
          </button>
        </div>
      </Modal.Header>
      {show && <Modal.Body>
        {cart?.cartData?.length > 0 ? (
          <div className="d-none d-md-block">
            <Row>
              <Col xl="6" md="4">
                <p className=" fw-bold ">
                  Product({cart?.cartData?.length}) Add to cart
                </p>
              </Col>{" "}
              <Col xl="6" md="8">
                <Row>
                  <Col md="4" className=" text-center ">
                    <p className=" fw-bold ">Quantity</p>
                  </Col>
                  <Col md="4" className=" text-center">
                    <p className="fw-bold">Price</p>
                  </Col>
                  <Col md="4" className=" text-center">
                    <p className="fw-bold">Action</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            {cart?.cartData?.map((item) => {return(
              <ProductDetail item={item}/>
            )})}
            {/* <Row key={item.productId}>
                <Col xl="6" md="4">
                  <img
                    width={40}
                    className="rounded"
                    src={item?.productImageUrl || NO_IMAGE_FOUND}
                    alt="Product Image"
                  />
                  <b className="ms-2">{item.productName}</b>
                </Col>
                <Col xl="6" md="8">
                  <Row sm="12">
                    <Col md="4" className="text-center">
                      <input
                        type="number"
                        style={{ width: "70px", height: "auto" }}
                        placeholder="Qty"
                        value={item.quantity}
                        min={1}
                        onChange={(e) => handleQuantityChange(item, +e.target.value,e.target)}
                      />
                    </Col>
                    <Col md="4" className="text-center">
                      <small>AED </small>
                      {item.salePrice * item.quantity}
                    </Col>
                    <Col md="4" className="text-center">
                      <p
                        className="text-decoration-none text-success"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(
                            DeleteProductFromCart({
                              data: cart?.cartData?.filter(
                                (finalItem) =>
                                  +finalItem.productId !== +item.productId
                              ),
                            })
                          );
                        }}
                      >
                        Remove
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
          </div>
        ) : (
          <p>No Products in cart!</p>
        )}
      </Modal.Body>}
      
      <div className="text-center" style={{ backgroundColor: "#dddddd" }}>
        <Row className="d-flex  justify-content-between align-items-center m-3">
          <Col sm="12" md="6" className="text-start ">
            <p>
              {cart?.cartData?.length > 0
                ? `You have ${cart?.cartData?.length} ${
                    cart?.cartData?.length > 1 ? "products" : "product"
                  } in your shopping cart.`
                : "Your cart is empty"}
            </p>
          </Col>
          <Col sm="12" md="6" className="mb-2 md:d-flex">
            <NavLink to="">
              <Button
                variant="secondary"
                className=" rounded-1 border-0 mx-1 my-2"
                onClick={handleClose}
              >
                Continue Shopping
              </Button>
            </NavLink>
            <NavLink to="/cart">
              <Button
                className="bg-primary px-4 mx-1 my-2"
                onClick={handleClose}
              >
                View Cart
              </Button>
            </NavLink>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default CartModal;

/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllCountries = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Country/login", req);
  // const data = await axios.post(`${baseUrl}/Country/login`, req);
  const data = await axios.post(`${baseUrl}Country/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};
const getAllAddresses = async (userId) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Address/login", req);
  // const data = await axios.post(`${baseUrl}/Address/login`, req);

  const data = await axios.post(`${baseUrl}Address/getAll?UserId=${+userId}`);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateAddress = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Address/login", req);
  // const data = await axios.post(`${baseUrl}/Address/login`, req);

  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
    }
  }
  const data = await axios.put(`${baseUrl}Address/update`, req, config);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createCountry = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Country/login", req);
  // const data = await axios.post(`${baseUrl}/Country/login`, req);
  const data = await axios.post(`${baseUrl}Country/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getCountryById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Country/login", req);
  // const data = await axios.post(`${baseUrl}/Country/login`, req);
  const data = await axios.get(`${baseUrl}Country/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateCountry = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Country/login", req);
  // const data = await axios.post(`${baseUrl}/Country/login`, req);
  const data = await axios.put(`${baseUrl}Country/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteCountry = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Country/login", req);
  // const data = await axios.post(`${baseUrl}/Country/login`, req);
  const data = await axios.delete(`${baseUrl}Country/delete?CountryId=${req}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

// Country contact

const getAllProvinces = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Province/login", req);
  // const data = await axios.post(`${baseUrl}/Province/login`, req);

  const data = await axios.post(`${baseUrl}Province/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createProvince = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Province/login", req);
  // const data = await axios.post(`${baseUrl}/Province/login`, req);
  const data = await axios.post(`${baseUrl}Province/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getProvinceById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Province/login", req);
  // const data = await axios.post(`${baseUrl}/Province/login`, req);
  const data = await axios.get(`${baseUrl}Province/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateProvince = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Province/login", req);
  // const data = await axios.post(`${baseUrl}/Province/login`, req);
  const data = await axios.put(`${baseUrl}Province/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteProvince = async (provinceId) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Province/login", provinceId);
  // const data = await axios.post(`${baseUrl}/Province/login`, provinceId);
  const data = await axios.delete(`${baseUrl}Province/delete?ProvinceId=${provinceId}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

// UIser City

const getAllCities = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/City/login", req);
  // const data = await axios.post(`${baseUrl}/City/login`, req);

  const data = await axios.post(`${baseUrl}City/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createCity = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/City/login", req);
  // const data = await axios.post(`${baseUrl}/City/login`, req);
  const data = await axios.post(`${baseUrl}City/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getCityById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/City/login", req);
  // const data = await axios.post(`${baseUrl}/City/login`, req);
  const data = await axios.get(`${baseUrl}City/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateCity = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/City/login", req);
  // const data = await axios.post(`${baseUrl}/City/login`, req);
  const data = await axios.put(`${baseUrl}City/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteCity = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/City/login", id);
  // const data = await axios.post(`${baseUrl}/City/login`, id);
  const data = await axios.delete(`${baseUrl}City/delete?CityId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};
const createAddress = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Address/login", req);
  // const data = await axios.post(`${baseUrl}/Address/login`, req);
  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
    }
  }
  const data = await axios.post(`${baseUrl}Address/create`, req, config);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteAddress = async (id) => {
  const data = await axios.delete(`${baseUrl}Address/delete?AddressId=${id}`);
  // const data = await axios.delete(`${baseUrl}Address/delete?AddressId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const addressService = {
  getAllCountries,
  getCountryById,
  updateCountry,
  deleteCountry,
  createCountry,

  getAllProvinces,
  getProvinceById,
  updateProvince,
  deleteProvince,
  createProvince,

  getAllCities,
  getCityById,
  updateCity,
  deleteCity,
  createCity,

  getAllAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
};

export default addressService;

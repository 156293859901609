/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./user.service";

const initialState = {
  getUserByIdLoading: false,
  getUserById: null,

  createContactLoading: false,
  createContact: null,

};

export const GetUserById = createAsyncThunk(
  "user/getById",
  async (id, thunkAPI) => {
    try {
      const response = await userService.getUserById(id);

      if (response.status === 200) {
        // if (moveToNext) {
        //   moveToNext(response?.data?.message, 'success');
        // }
      } else {
        // moveToNext(response?.data?.message, 'error');
      }
      return response;
    } catch (error) {
      // moveToNext(error?.message, 'error');
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateContact = createAsyncThunk(
  "contact/create",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await userService.createContact(data);

      if (response.status === 200 || response.data.succeeded) {
        if (moveToNext) {
          moveToNext(response?.data?.message, "success");
        }
      } else {
        moveToNext(response?.data?.message, "error");
      }
      return response;
    } catch (error) {
      moveToNext(error?.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateContact.pending, (state) => {
        state.createContactLoading = true;
      })
      .addCase(CreateContact.fulfilled, (state, action) => {
        state.createContactLoading = false;
        state.createContact = action.payload.data.data;
      })
      .addCase(CreateContact.rejected, (state, action) => {
        state.createContactLoading = false;
        state.createContact = action.payload;
      })
      .addCase(GetUserById.pending, (state) => {
        state.getUserByIdLoading = true;
        state.getUserById = null;
      })
      .addCase(GetUserById.fulfilled, (state, action) => {
        state.getUserByIdLoading = false;
        state.getUserById = action.payload.data.data;
      })
      .addCase(GetUserById.rejected, (state, action) => {
        state.getUserByIdLoading = false;
        state.getUserById = action.payload;
      });
    
  },
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;

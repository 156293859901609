/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllFilesAsync = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/DiscountCoupon/login", req);
  // const data = await axios.post(`${baseUrl}/DiscountCoupon/login`, req);
  const data = await axios.post(`${baseUrl}DiscountCoupon/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const uploadFile = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/DiscountCoupon/login", req);
  // const data = await axios.post(`${baseUrl}/DiscountCoupon/login`, req);
  const data = await axios.post(`${baseUrl}Buckets/uploadFile`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getFileByKeyAsync = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/DiscountCoupon/login", req);
  // const data = await axios.post(`${baseUrl}/DiscountCoupon/login`, req);
  const data = await axios.get(`${baseUrl}DiscountCoupon/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateCoupon = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/DiscountCoupon/login", req);
  // const data = await axios.post(`${baseUrl}/DiscountCoupon/login`, req);
  const data = await axios.put(`${baseUrl}DiscountCoupon/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteCoupon = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/DiscountCoupon/login", id);
  // const data = await axios.post(`${baseUrl}/DiscountCoupon/login`, id);
  const data = await axios.delete(`${baseUrl}DiscountCoupon/delete?DiscountCouponId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const couponService = {
  getAllFilesAsync,
  getFileByKeyAsync,
  updateCoupon,
  deleteCoupon,
  uploadFile,
};

export default couponService;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
    cartData: JSON.parse(localStorage.getItem('cartData')) || [],
    cartLoading: false,
    discountAmount: 0,
    cartSubTotal: 0
};

export const AddUpdateProductToCart = createAsyncThunk('cart/add', async ({ data, moveToNext }, thunkAPI) => {
    try {
        return data;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const ClearCart = createAsyncThunk('cart/clear', async ({ data, moveToNext }, thunkAPI) => {
    
    try {
        return data;
        // return data;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const GetCartSubTotal = createAsyncThunk('cart/subTotal', async (data, thunkAPI) => {

    try {

        return data;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const DeleteProductFromCart = createAsyncThunk('cart/delete', async ({ data, moveToNext }, thunkAPI) => {

    try {
        return data;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const FinalizeCart = createAsyncThunk('cart/finalize', async ({ data, moveToNext }, thunkAPI) => {

    try {
        moveToNext();
        return data;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // isLoading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddUpdateProductToCart.pending, (state, action) => {
                state.cartLoading = true;
                
            })
            .addCase(AddUpdateProductToCart.fulfilled, (state, action) => {
                state.cartLoading = false;
                let finalData = current(state.cartData);
                let foundIndex = finalData.findIndex((item) => item.productId === action.payload.productId)
                if (foundIndex === -1) {
                    finalData = [...finalData, action.payload]
                } else {
                    // finalData[foundIndex] = action.payload
                    finalData = finalData.map((finalItem) => {
                        let updatedObject = {...finalItem};
                        if (finalItem.productId === action.payload.productId) {
                            // updatedObject = action.payload
                            if(action?.payload?.replaceQuantity){
                                updatedObject.quantity=action.payload.quantity
                            }else{
                                updatedObject.quantity=updatedObject.quantity+action.payload.quantity
                            }
                            // updatedObject.salePrice=updatedObject.salePrice+action.payload.salePrice
                        }
                        return updatedObject;
                  // finalData[finalItem].quantity += action.payload.quantity;

                    })
                }
                state.cartData = finalData;
                localStorage.setItem('cartData', JSON.stringify(finalData));
            })
            .addCase(DeleteProductFromCart.pending, (state, action) => {
                state.cartLoading = true;
            }).addCase(DeleteProductFromCart.fulfilled, (state, action) => {
                state.cartLoading = false;
                // let finalData = current(state.cartData);

                state.cartData = action.payload
                localStorage.setItem('cartData', JSON.stringify(action.payload));
            }).addCase(GetCartSubTotal.fulfilled, (state, action) => {

                const finalData = current(state.cartData);
                let subTotal = 0
                for (let i = 0; i < finalData.length; i++) {
                    subTotal = subTotal + (finalData[i].quantity * finalData[i].salePrice)
                }

                state.cartSubTotal = subTotal
            }).addCase(ClearCart.fulfilled, (state, action) => {
                localStorage.removeItem('cartData');
                state.cartData = [];
                state.cartSubTotal = 0;
            }).addCase(FinalizeCart.fulfilled, (state, action) => {

                state.discountAmount = action.payload.type === "percentage" ? state.cartSubTotal * (action.payload.amount / 100) : state.cartSubTotal - action.payload.amount;
                state.cartSubTotal = action.payload.type === "percentage" ? (state.cartSubTotal - state.cartSubTotal * (action.payload.amount / 100)) : state.cartSubTotal - action.payload.amount;
            })
    },
});

export const { reset } = cartSlice.actions;

export default cartSlice.reducer;

import axios from 'axios';
import { baseUrl } from '../../config';

// const BASE_URL = 'http://glareapis.growcrmpro.com'; // Update with your actual base URL
const createMeeting = async (req) => {
  try {
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("varskart_customer")).token}`,
    //   }
    // }

    const response = await axios.post(`${baseUrl}IntestorEmail/InvestorEmail`, req );
    if (response?.data?.succeeded) {
      // Handle any specific logic for a successful response
    } else {
      throw response?.data?.message || 'An error occurred during RFQ creation';
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error.message || 'An error occurred during RFQ creation';
  }
};



const meetingService = {
    createMeeting,
};

export default meetingService;


import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getMetas = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);

  const data = await axios.post(`${baseUrl}Filters/GetMetas`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};


const metaService = {
  getMetas,
 
};

export default metaService;

import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllBrands = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Brand/login", req);
  // const data = await axios.post(`${baseUrl}/Brand/login`, req);

  const data = await axios.post(`${baseUrl}Brand/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const createBrand = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Brand/login", req);
  // const data = await axios.post(`${baseUrl}/Brand/login`, req);
  const data = await axios.post(`${baseUrl}Brand/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const getBrandById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Brand/login", req);
  // const data = await axios.post(`${baseUrl}/Brand/login`, req);
  const data = await axios.get(`${baseUrl}Brand/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const updateBrand = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Brand/login", req);
  // const data = await axios.post(`${baseUrl}/Brand/login`, req);
  const data = await axios.put(`${baseUrl}Brand/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const deleteBrand = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Brand/login", id);
  // const data = await axios.post(`${baseUrl}/Brand/login`, id);
  const data = await axios.delete(`${baseUrl}Brand/delete?BrandId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

// Brand contact

const getAllBrandCategories = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/BrandCategory/login", req);
  // const data = await axios.post(`${baseUrl}/BrandCategory/login`, req);

  const data = await axios.post(`${baseUrl}BrandCategory/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const createBrandCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/BrandCategory/login", req);
  // const data = await axios.post(`${baseUrl}/BrandCategory/login`, req);
  const data = await axios.post(`${baseUrl}BrandCategory/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const getBrandCategoryById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/BrandCategory/login", req);
  // const data = await axios.post(`${baseUrl}/BrandCategory/login`, req);
  const data = await axios.get(`${baseUrl}BrandCategory/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const updateBrandCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/BrandCategory/login", req);
  // const data = await axios.post(`${baseUrl}/BrandCategory/login`, req);
  const data = await axios.put(`${baseUrl}BrandCategory/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const deleteBrandCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/BrandCategory/login", data);
  // const data = await axios.post(`${baseUrl}/BrandCategory/login`, data);
  const data = await axios.delete(`${baseUrl}BrandCategory/delete?BrandCategoryId=${req.id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
//     throw data.message;
//   }
  return data;
};

const brandService = {
  getAllBrands,
  getBrandById,
  updateBrand,
  deleteBrand,
  createBrand,

  getAllBrandCategories,
  getBrandCategoryById,
  updateBrandCategory,
  deleteBrandCategory,
  createBrandCategory,
};

export default brandService;

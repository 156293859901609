/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import departmentService from './department.service';

const initialState = {
  getAllDepartments: [],
  getAllDepartmentLoading: false,

  createDepartmentLoading: false,
  createDepartment: null,

  getDepartmentByIdLoading: false,
  getDepartmentById: null,

  deleteDepartmentLoading: false,
  deleteDepartment: null,

  updateDepartmentLoading: false,
  updateDepartment: null,

  getAllDepartmentCategories: [],
  getAllDepartmentCategoryLoading: false,

  createDepartmentCategoryLoading: false,
  createDepartmentCategory: null,

  getDepartmentCategoryByIdLoading: false,
  getDepartmentCategoryById: null,

  deleteDepartmentCategoryLoading: false,
  deleteDepartmentCategory: null,

  updateDepartmentCategoryLoading: false,
  updateDepartmentCategory: null,

  getAllDepartmentSubCategories: [],
  getAllDepartmentSubCategoryLoading: false,

  createDepartmentSubCategoryLoading: false,
  createDepartmentSubCategory: null,

  getDepartmentSubCategoryByIdLoading: false,
  getDepartmentSubCategoryById: null,

  deleteDepartmentSubCategoryLoading: false,
  deleteDepartmentSubCategory: null,

  updateDepartmentSubCategoryLoading: false,
  updateDepartmentSubCategory: null,
};

export const GetAllDepartments = createAsyncThunk('department/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartments(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateDepartment = createAsyncThunk('department/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.createDepartment(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateDepartment = createAsyncThunk('department/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.updateDepartment(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetDepartmentById = createAsyncThunk('department/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartments(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteDepartment = createAsyncThunk('department/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.deleteDepartment(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllFilterCategories = createAsyncThunk('department/categories/filter', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllFilterCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const GetAllDepartmentCategories = createAsyncThunk('department/categories/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartmentCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateDepartmentCategory = createAsyncThunk('department/categories/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.createDepartmentCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateDepartmentCategory = createAsyncThunk('department/categories/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.updateDepartmentCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetDepartmentCategoryById = createAsyncThunk('department/categories/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartmentCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteDepartmentCategory = createAsyncThunk('department/categories/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.deleteDepartmentCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllDepartmentSubCategories = createAsyncThunk('department/subCategories/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartmentSubCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateDepartmentSubCategory = createAsyncThunk('department/subCategories/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.createDepartmentSubCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateDepartmentSubCategory = createAsyncThunk('department/subCategories/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.updateDepartmentSubCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetDepartmentSubCategoryById = createAsyncThunk('department/subCategories/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.getAllDepartmentSubCategories(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteDepartmentSubCategory = createAsyncThunk('department/subCategories/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await departmentService.deleteDepartmentSubCategory(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllDepartments.pending, (state) => {
        state.getAllDepartmentLoading = true;
      })
      .addCase(GetAllDepartments.fulfilled, (state, action) => {
        state.getAllDepartmentLoading = false;
        state.getAllDepartments = action.payload.data;
      })
      .addCase(GetAllDepartments.rejected, (state, action) => {
        state.getAllDepartmentLoading = false;
        state.getAllDepartments = action.payload;
      })
      .addCase(CreateDepartment.pending, (state) => {
        state.createDepartmentLoading = true;
      })
      .addCase(CreateDepartment.fulfilled, (state, action) => {
        state.createDepartmentLoading = false;
        state.createDepartment = action.payload;
      })
      .addCase(CreateDepartment.rejected, (state, action) => {
        state.createDepartmentLoading = false;
        state.createDepartment = action.payload;
      })
      .addCase(UpdateDepartment.pending, (state) => {
        state.updateDepartmentLoading = true;
      })
      .addCase(UpdateDepartment.fulfilled, (state, action) => {
        state.updateDepartmentLoading = false;
        state.updateDepartment = action.payload;
      })
      .addCase(UpdateDepartment.rejected, (state, action) => {
        state.updateDepartmentLoading = false;
        state.updateDepartment = action.payload;
      })
      .addCase(GetDepartmentById.pending, (state, action) => {
        state.getDepartmentByIdLoading = true;
        state.getDepartmentById = action.payload;
      })
      .addCase(GetDepartmentById.fulfilled, (state, action) => {
        state.getDepartmentByIdLoading = false;
        state.getDepartmentById = action.payload;
      })
      .addCase(GetDepartmentById.rejected, (state, action) => {
        state.getDepartmentByIdLoading = false;
        state.getDepartmentById = action.payload;
      })
      .addCase(DeleteDepartment.pending, (state) => {
        state.deleteDepartmentLoading = true;
      })
      .addCase(DeleteDepartment.fulfilled, (state, action) => {
        state.deleteDepartmentLoading = false;
        state.deleteDepartment = action.payload;
      })
      .addCase(DeleteDepartment.rejected, (state, action) => {
        state.deleteDepartmentLoading = false;
        state.deleteDepartment = action.payload;
      });
    builder
      .addCase(GetAllDepartmentCategories.pending, (state) => {
        state.getAllDepartmentCategoryLoading = true;
      })
      .addCase(GetAllDepartmentCategories.fulfilled, (state, action) => {
        state.getAllDepartmentCategoryLoading = false;
        state.getAllDepartmentCategories = action.payload.data;
      })
      .addCase(GetAllDepartmentCategories.rejected, (state, action) => {
        state.getAllDepartmentCategoryLoading = false;
        state.getAllDepartmentCategories = action.payload;
      })
      .addCase(GetAllFilterCategories.pending, (state) => {
        state.getAllFilterCategoriesLoading = true;
      })
      .addCase(GetAllFilterCategories.fulfilled, (state, action) => {
        state.getAllFilterCategoriesLoading = false;
        state.getAllFilterCategories = action.payload.data;
      })
      .addCase(GetAllFilterCategories.rejected, (state, action) => {
        state.getAllFilterCategoriesLoading = false;
        state.getAllFilterCategories = action.payload;
      })
      .addCase(CreateDepartmentCategory.pending, (state) => {
        state.createDepartmentCategoryLoading = true;
      })
      .addCase(CreateDepartmentCategory.fulfilled, (state, action) => {
        state.createDepartmentCategoryLoading = false;
        state.createDepartmentCategory = action.payload;
      })
      .addCase(CreateDepartmentCategory.rejected, (state, action) => {
        state.createDepartmentCategoryLoading = false;
        state.createDepartmentCategory = action.payload;
      })
      .addCase(UpdateDepartmentCategory.pending, (state) => {
        state.updateDepartmentCategoryLoading = true;
      })
      .addCase(UpdateDepartmentCategory.fulfilled, (state, action) => {
        state.updateDepartmentCategoryLoading = false;
        state.updateDepartmentCategory = action.payload;
      })
      .addCase(UpdateDepartmentCategory.rejected, (state, action) => {
        state.updateDepartmentCategoryLoading = false;
        state.updateDepartmentCategory = action.payload;
      })
      .addCase(GetDepartmentCategoryById.pending, (state, action) => {
        state.getDepartmentCategoryByIdLoading = true;
        state.getDepartmentCategoryById = action.payload;
      })
      .addCase(GetDepartmentCategoryById.fulfilled, (state, action) => {
        state.getDepartmentCategoryByIdLoading = false;
        state.getDepartmentCategoryById = action.payload;
      })
      .addCase(GetDepartmentCategoryById.rejected, (state, action) => {
        state.getDepartmentCategoryByIdLoading = false;
        state.getDepartmentCategoryById = action.payload;
      })
      .addCase(DeleteDepartmentCategory.pending, (state) => {
        state.deleteDepartmentCategoryLoading = true;
      })
      .addCase(DeleteDepartmentCategory.fulfilled, (state, action) => {
        state.deleteDepartmentCategoryLoading = false;
        state.deleteDepartmentCategory = action.payload;
      })
      .addCase(DeleteDepartmentCategory.rejected, (state, action) => {
        state.deleteDepartmentCategoryLoading = false;
        state.deleteDepartmentCategory = action.payload;
      });
    builder
      .addCase(GetAllDepartmentSubCategories.pending, (state) => {
        state.getAllDepartmentSubCategoryLoading = true;
      })
      .addCase(GetAllDepartmentSubCategories.fulfilled, (state, action) => {
        state.getAllDepartmentSubCategoryLoading = false;
        state.getAllDepartmentSubCategories = action.payload.data;
      })
      .addCase(GetAllDepartmentSubCategories.rejected, (state, action) => {
        state.getAllDepartmentSubCategoryLoading = false;
        state.getAllDepartmentSubCategories = action.payload;
      })
      .addCase(CreateDepartmentSubCategory.pending, (state) => {
        state.createDepartmentSubCategoryLoading = true;
      })
      .addCase(CreateDepartmentSubCategory.fulfilled, (state, action) => {
        state.createDepartmentSubCategoryLoading = false;
        state.createDepartmentSubCategory = action.payload;
      })
      .addCase(CreateDepartmentSubCategory.rejected, (state, action) => {
        state.createDepartmentSubCategoryLoading = false;
        state.createDepartmentSubCategory = action.payload;
      })
      .addCase(UpdateDepartmentSubCategory.pending, (state) => {
        state.updateDepartmentSubCategoryLoading = true;
      })
      .addCase(UpdateDepartmentSubCategory.fulfilled, (state, action) => {
        state.updateDepartmentSubCategoryLoading = false;
        state.updateDepartmentSubCategory = action.payload;
      })
      .addCase(UpdateDepartmentSubCategory.rejected, (state, action) => {
        state.updateDepartmentSubCategoryLoading = false;
        state.updateDepartmentSubCategory = action.payload;
      })
      .addCase(GetDepartmentSubCategoryById.pending, (state, action) => {
        state.getDepartmentSubCategoryByIdLoading = true;
        state.getDepartmentSubCategoryById = action.payload;
      })
      .addCase(GetDepartmentSubCategoryById.fulfilled, (state, action) => {
        state.getDepartmentSubCategoryByIdLoading = false;
        state.getDepartmentSubCategoryById = action.payload;
      })
      .addCase(GetDepartmentSubCategoryById.rejected, (state, action) => {
        state.getDepartmentSubCategoryByIdLoading = false;
        state.getDepartmentSubCategoryById = action.payload;
      })
      .addCase(DeleteDepartmentSubCategory.pending, (state) => {
        state.deleteDepartmentSubCategoryLoading = true;
      })
      .addCase(DeleteDepartmentSubCategory.fulfilled, (state, action) => {
        state.deleteDepartmentSubCategoryLoading = false;
        state.deleteDepartmentSubCategory = action.payload;
      })
      .addCase(DeleteDepartmentSubCategory.rejected, (state, action) => {
        state.deleteDepartmentSubCategoryLoading = false;
        state.deleteDepartmentSubCategory = action.payload;
      });
  },
});

export const { reset } = departmentSlice.actions;

export default departmentSlice.reducer;

/* eslint-disable no-debugger */
import axios from 'axios';
import { baseUrl } from '../../config';

const getAllFields = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Field/login", req);
  // const data = await axios.post(`${baseUrl}/Field/login`, req);
  const data = await axios.post(`${baseUrl}Field/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createField = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Field/login", req);
  // const data = await axios.post(`${baseUrl}/Field/login`, req);
  const data = await axios.post(`${baseUrl}Field/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getFieldById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Field/login", req);
  // const data = await axios.post(`${baseUrl}/Field/login`, req);
  const data = await axios.get(`${baseUrl}Field/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateField = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Field/login", req);
  // const data = await axios.post(`${baseUrl}/Field/login`, req);
  const data = await axios.put(`${baseUrl}Field/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteField = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Field/login", id);
  // const data = await axios.post(`${baseUrl}/Field/login`, id);
  const data = await axios.delete(`${baseUrl}Field/delete?FieldId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getAllOptions = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Options/login", req);
  // const data = await axios.post(`${baseUrl}/Options/login`, req);
  const data = await axios.post(`${baseUrl}Options/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const createOption = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Options/login", req);
  // const data = await axios.post(`${baseUrl}/Options/login`, req);
  const data = await axios.post(`${baseUrl}Options/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const getOptionById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Options/login", req);
  // const data = await axios.post(`${baseUrl}/Options/login`, req);
  const data = await axios.get(`${baseUrl}Options/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const updateOption = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Options/login", req);
  // const data = await axios.post(`${baseUrl}/Options/login`, req);
  const data = await axios.put(`${baseUrl}Options/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const deleteOption = async (id) => {
  // const data = await axios.post(`${baseUrl}/Options/login`, id);
  const data = await axios.delete(`${baseUrl}Options/delete?OptionId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("glare_ecom", JSON.stringify(data?.data?.data));
  } else {
    throw data.message;
  }
  return data;
};

const dynamicFieldService = {
  getAllFields,
  getFieldById,
  updateField,
  deleteField,
  createField,

  getAllOptions,
  getOptionById,
  updateOption,
  deleteOption,
  createOption,
};

export default dynamicFieldService;

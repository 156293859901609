/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import searchItemService from './searchItem.service';

const initialState = {
    getAllSearchItems: [],
    getAllSearchItemsLoading: false,

};

export const GetAllSearchItems = createAsyncThunk('searchItem/getAll', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await searchItemService.getAllSearchItems(data);

        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});




export const SearchItemSlice = createSlice({
    name: 'searchItem',
    initialState,
    reducers: {
        // isLoading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllSearchItems.pending, (state) => {
                state.getAllSearchItemsLoading = true;
            })
            .addCase(GetAllSearchItems.fulfilled, (state, action) => {
                state.getAllSearchItemsLoading = false;
                state.getAllSearchItems = action.payload.data;
            })
            .addCase(GetAllSearchItems.rejected, (state, action) => {
                state.getAllSearchItemsLoading = false;
                state.getAllSearchItems = action.payload;
            })
    },
});

export const { reset } = SearchItemSlice.actions;

export default SearchItemSlice.reducer;
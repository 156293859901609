import axios from 'axios';
import { baseUrl } from '../../config';

// import api from "../../";

const getAllDepartments = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);

  const data = await axios.post(`${baseUrl}Dept/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const createDepartment = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);
  const data = await axios.post(`${baseUrl}Dept/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const getDepartmentById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);
  const data = await axios.get(`${baseUrl}Dept/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const updateDepartment = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", req);
  // const data = await axios.post(`${baseUrl}/Dept/login`, req);
  const data = await axios.put(`${baseUrl}Dept/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const deleteDepartment = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Dept/login", id);
  // const data = await axios.post(`${baseUrl}/Dept/login`, id);
  const data = await axios.delete(`${baseUrl}Dept/delete?DeptId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

// Department

const getAllDepartmentCategories = async (req) => {

  const data = await axios.post(`${baseUrl}Category/getAll`, req);
  return data;
};
const getAllFilterCategories = async (req) => {

  const data = await axios.post(`${baseUrl}Category/FilterCategory`, req);
  return data;
};

const createDepartmentCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Category/login", req);
  // const data = await axios.post(`${baseUrl}/Category/login`, req);
  const data = await axios.post(`${baseUrl}Category/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const getDepartmentCategoryById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Category/login", req);
  // const data = await axios.post(`${baseUrl}/Category/login`, req);
  const data = await axios.get(`${baseUrl}Category/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const updateDepartmentCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Category/login", req);
  // const data = await axios.post(`${baseUrl}/Category/login`, req);
  const data = await axios.put(`${baseUrl}Category/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const deleteDepartmentCategory = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Category/login", id);
  // const data = await axios.post(`${baseUrl}/Category/login`, id);
  const data = await axios.delete(`${baseUrl}Category/delete?CategoryId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

// Department contact

const getAllDepartmentSubCategories = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/Category/login", req);
  // const data = await axios.post(`${baseUrl}/Category/login`, req);

  const data = await axios.post(`${baseUrl}SubCategory/getAll`, req);

  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const createDepartmentSubCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/SubCategory/login", req);
  // const data = await axios.post(`${baseUrl}/SubCategory/login`, req);
  const data = await axios.post(`${baseUrl}SubCategory/create`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const getDepartmentSubCategoryById = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/SubCategory/login", req);
  // const data = await axios.post(`${baseUrl}/SubCategory/login`, req);
  const data = await axios.get(`${baseUrl}SubCategory/get`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const updateDepartmentSubCategory = async (req) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/SubCategory/login", req);
  // const data = await axios.post(`${baseUrl}/SubCategory/login`, req);
  const data = await axios.put(`${baseUrl}SubCategory/update`, req);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const deleteDepartmentSubCategory = async (id) => {
  // let baseUrl = process.env.API_ENDPOINT

  // const data = await api.post("/SubCategory/login", id);
  // const data = await axios.post(`${baseUrl}/SubCategory/login`, id);
  const data = await axios.delete(`${baseUrl}SubCategory/delete?SubCategoryId=${id}`);
  if (data?.data?.succeeded) {
    // localStorage.setItem("varskart_customer", JSON.stringify(data?.data?.data));
  } // else {
  //     throw data.message;
  //   }
  return data;
};

const departmentService = {
  getAllDepartments,
  getDepartmentById,
  updateDepartment,
  deleteDepartment,
  createDepartment,

  getAllDepartmentCategories,
  getAllFilterCategories,
  getDepartmentCategoryById,
  updateDepartmentCategory,
  deleteDepartmentCategory,
  createDepartmentCategory,

  getAllDepartmentSubCategories,
  getDepartmentSubCategoryById,
  updateDepartmentSubCategory,
  deleteDepartmentSubCategory,
  createDepartmentSubCategory,
};

export default departmentService;

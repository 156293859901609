/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addressService from './address.service';

const initialState = {
  getAllCountries: [],
  getAllCountriesLoading: false,

  createCountryLoading: false,
  createCountry: null,

  getCountryByIdLoading: false,
  getCountryById: null,

  deleteCountryLoading: false,
  deleteCountry: null,

  updateCountryLoading: false,
  updateCountry: null,

  getAllProvinces: [],
  getAllProvincesLoading: false,

  createProvinceLoading: false,
  createProvince: null,

  getProvinceByIdLoading: false,
  getProvinceById: null,

  deleteProvinceLoading: false,
  deleteProvince: null,

  updateProvinceLoading: false,
  updateProvince: null,

  getAllCities: [],
  getAllCitiesLoading: false,

  createCityLoading: false,
  createCity: null,

  getCityByIdLoading: false,
  getCityById: null,

  deleteCityLoading: false,
  deleteCity: null,

  updateCityLoading: false,
  updateCity: null,

  getAllAddresses: [],
  getAllAddressLoading: false,

  
  createAddressLoading: false,
  createAddress: null,

  updateAddressLoading: false,
  updateAddress: null,

  deleteAddressLoading: false,
  deleteAddress: null,
};


export const GetAllAddresses = createAsyncThunk('address/getAll', async ({ userId, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllAddresses(userId);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateAddress = createAsyncThunk('address/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.createAddress(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateAddress = createAsyncThunk('address/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.updateAddress(data);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteAddress = createAsyncThunk('address/delete', async ({ addressId, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.deleteAddress(addressId);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllCountries = createAsyncThunk('country/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllCountries(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateCountry = createAsyncThunk('country/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.createCountry(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateCountry = createAsyncThunk('country/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.updateCountry(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetCountryById = createAsyncThunk('country/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllCountries(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteCountry = createAsyncThunk('country/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.deleteCountry(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllCities = createAsyncThunk('city/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllCities(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateCity = createAsyncThunk('city/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.createCity(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateCity = createAsyncThunk('city/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.updateCity(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetCityById = createAsyncThunk('city/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllCities(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteCity = createAsyncThunk('city/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.deleteCity(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllProvinces = createAsyncThunk('province/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllProvinces(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateProvince = createAsyncThunk('province/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.createProvince(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateProvince = createAsyncThunk('province/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.updateProvince(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetProvinceById = createAsyncThunk('province/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.getAllProvinces(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteProvince = createAsyncThunk('province/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await addressService.deleteProvince(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addressSlice = createSlice({
  name: 'Address',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCountries.pending, (state) => {
        state.getAllCountriesLoading = true;
      })
      .addCase(GetAllCountries.fulfilled, (state, action) => {
        state.getAllCountriesLoading = false;
        state.getAllCountries = action.payload.data?.data;
      })
      .addCase(GetAllCountries.rejected, (state) => {
        state.getAllCountriesLoading = false;
        state.getAllCountries = [];
      })
      .addCase(CreateCountry.pending, (state) => {
        state.createCountryLoading = true;
      })
      .addCase(CreateCountry.fulfilled, (state, action) => {
        state.createCountryLoading = false;
        state.createCountry = action.payload;
      })
      .addCase(CreateCountry.rejected, (state, action) => {
        state.createCountryLoading = false;
        state.createCountry = action.payload;
      })
      .addCase(UpdateCountry.pending, (state) => {
        state.updateCountryLoading = true;
      })
      .addCase(UpdateCountry.fulfilled, (state, action) => {
        state.updateCountryLoading = false;
        state.updateCountry = action.payload;
      })
      .addCase(UpdateCountry.rejected, (state, action) => {
        state.updateCountryLoading = false;
        state.updateCountry = action.payload;
      })
      .addCase(GetCountryById.pending, (state, action) => {
        state.getCountryByIdLoading = true;
        state.getCountryById = action.payload;
      })
      .addCase(GetCountryById.fulfilled, (state, action) => {
        state.getCountryByIdLoading = false;
        state.getCountryById = action.payload;
      })
      .addCase(GetCountryById.rejected, (state, action) => {
        state.getCountryByIdLoading = false;
        state.getCountryById = action.payload;
      })
      .addCase(DeleteCountry.pending, (state) => {
        state.deleteCountryLoading = true;
      })
      .addCase(DeleteCountry.fulfilled, (state, action) => {
        state.deleteCountryLoading = false;
        state.deleteCountry = action.payload;
      })
      .addCase(DeleteCountry.rejected, (state, action) => {
        state.deleteCountryLoading = false;
        state.deleteCountry = action.payload;
      })
      .addCase(GetAllProvinces.pending, (state) => {
        state.getAllProvincesLoading = true;
      })
      .addCase(GetAllProvinces.fulfilled, (state, action) => {
        state.getAllProvincesLoading = false;
        state.getAllProvinces = action.payload.data.data;
      })
      .addCase(GetAllProvinces.rejected, (state, action) => {
        state.getAllProvincesLoading = false;
        state.getAllProvinces = action.payload;
      })
      .addCase(CreateProvince.pending, (state) => {
        state.createProvinceLoading = true;
      })
      .addCase(CreateProvince.fulfilled, (state, action) => {
        state.createProvinceLoading = false;
        state.createProvince = action.payload.data.data;
      })
      .addCase(CreateProvince.rejected, (state, action) => {
        state.createProvinceLoading = false;
        state.createProvince = action.payload;
      })
      .addCase(UpdateProvince.pending, (state) => {
        state.updateProvinceLoading = true;
      })
      .addCase(UpdateProvince.fulfilled, (state, action) => {
        state.updateProvinceLoading = false;
        state.updateProvince = action.payload.data.data;
      })
      .addCase(UpdateProvince.rejected, (state, action) => {
        state.updateProvinceLoading = false;
        state.updateProvince = action.payload;
      })
      .addCase(GetProvinceById.pending, (state, action) => {
        state.getProvinceByIdLoading = true;
        state.getProvinceById = action.payload;
      })
      .addCase(GetProvinceById.fulfilled, (state, action) => {
        state.getProvinceByIdLoading = false;
        state.getProvinceById = action.payload.data.data;
      })
      .addCase(GetProvinceById.rejected, (state, action) => {
        state.getProvinceByIdLoading = false;
        state.getProvinceById = action.payload;
      })
      .addCase(DeleteProvince.pending, (state) => {
        state.deleteProvinceLoading = true;
      })
      .addCase(DeleteProvince.fulfilled, (state, action) => {
        state.deleteProvinceLoading = false;
        state.deleteProvince = action.payload.data.data;
      })
      .addCase(DeleteProvince.rejected, (state, action) => {
        state.deleteProvinceLoading = false;
        state.deleteProvince = action.payload;
      })
      .addCase(GetAllCities.pending, (state) => {
        state.getAllCitiesLoading = true;
      })
      .addCase(GetAllCities.fulfilled, (state, action) => {
        state.getAllCitiesLoading = false;
        state.getAllCities = action.payload.data.data;
      })
      .addCase(GetAllCities.rejected, (state, action) => {
        state.getAllCitiesLoading = false;
        state.getAllCities = action.payload;
      })
      .addCase(CreateCity.pending, (state) => {
        state.createCityLoading = true;
      })
      .addCase(CreateCity.fulfilled, (state, action) => {
        state.createCityLoading = false;
        state.createCity = action.payload.data.data;
      })
      .addCase(CreateCity.rejected, (state, action) => {
        state.createCityLoading = false;
        state.createCity = action.payload;
      })
      .addCase(UpdateCity.pending, (state) => {
        state.updateCityLoading = true;
      })
      .addCase(UpdateCity.fulfilled, (state, action) => {
        state.updateCityLoading = false;
        state.updateCity = action.payload.data.data;
      })
      .addCase(UpdateCity.rejected, (state, action) => {
        state.updateCityLoading = false;
        state.updateCity = action.payload;
      })
      .addCase(GetCityById.pending, (state, action) => {
        state.getCityByIdLoading = true;
        state.getCityById = action.payload;
      })
      .addCase(GetCityById.fulfilled, (state, action) => {
        state.getCityByIdLoading = false;
        state.getCityById = action.payload.data.data;
      })
      .addCase(GetCityById.rejected, (state, action) => {
        state.getCityByIdLoading = false;
        state.getCityById = action.payload;
      })
      .addCase(DeleteCity.pending, (state) => {
        state.deleteCityLoading = true;
      })
      .addCase(DeleteCity.fulfilled, (state, action) => {
        state.deleteCityLoading = false;
        state.deleteCity = action.payload.data.data;
      })
      .addCase(DeleteCity.rejected, (state, action) => {
        state.deleteCityLoading = false;
        state.deleteCity = action.payload;
      })
      .addCase(GetAllAddresses.pending, (state) => {
        state.getAllAddressLoading = true;
      })
      .addCase(GetAllAddresses.fulfilled, (state, action) => {
        state.getAllAddressLoading = false;
        state.getAllAddresses = action.payload.data.data;
      })
      .addCase(GetAllAddresses.rejected, (state) => {
        state.getAllAddressLoading = false;
        state.getAllAddresses = [];
      })
      .addCase(UpdateAddress.pending, (state) => {
        state.updateAddressLoading = true;
      })
      .addCase(UpdateAddress.fulfilled, (state, action) => {
        state.updateAddressLoading = false;
        state.updateAddress = action.payload.data.data;
      })
      .addCase(UpdateAddress.rejected, (state, action) => {
        state.updateAddressLoading = false;
        state.updateAddress = action.payload;
      })
      .addCase(DeleteAddress.pending, (state) => {
        state.deleteAddressLoading = true;
      })
      .addCase(DeleteAddress.fulfilled, (state, action) => {
        state.deleteAddressLoading = false;
        state.deleteAddress = action.payload.data.data;
      })
      .addCase(DeleteAddress.rejected, (state, action) => {
        state.deleteAddressLoading = false;
        state.deleteAddress = action.payload;
      });
  },
});

export const { reset } = addressSlice.actions;

export default addressSlice.reducer;

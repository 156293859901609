import SubCategory_1 from '../SubCategories/Artboard 1 copy 13.png';
import SubCategory_2 from '../SubCategories/Artboard 1 copy 15.png';
import SubCategory_3 from '../SubCategories/Artboard 1 copy 18.png';
import SubCategory_4 from '../SubCategories/Artboard 1 copy 10.png';
import SubCategory_5 from '../SubCategories/Artboard 1 copy 11.png';
import SubCategory_6 from '../SubCategories/Artboard 1 copy 12.png';
import SubCategory_7 from '../SubCategories/Artboard 1 copy 16.png';
import SubCategory_8 from '../SubCategories/Artboard 1 copy 14.png';
import SubCategory_9 from '../SubCategories/Artboard 1 copy 17.png';
import SubCategory_10 from '../SubCategories/Artboard 1 copy 20.png';
import SubCategory_11 from '../SubCategories/Artboard 1 copy 21.png';
import SubCategory_12 from '../SubCategories/Artboard 1 copy 22.png';
import SubCategory_13 from '../SubCategories/Artboard 1 copy 2.png';
import SubCategory_14 from '../SubCategories/Artboard 1 copy 3.png';
import SubCategory_15 from '../SubCategories/Artboard 1 copy 4.png';
import Map from '../../svg/location-dot-solid.svg';
import Call from '../../svg/phone-solid.svg';
import Email from '../../svg/envelope-solid.svg';
import Info from "../../svg/info.svg"



const images = {
    Info,
    Map,
    Call,
    Email,
    SubCategory_1,
    SubCategory_2,
    SubCategory_3,
    SubCategory_4,
    SubCategory_5,
    SubCategory_6,
    SubCategory_7,
    SubCategory_8,
    SubCategory_9,
    SubCategory_10,
    SubCategory_11,
    SubCategory_12,
    SubCategory_13,
    SubCategory_14,
    SubCategory_15,
};

export default images;

/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import couponService from './coupon.service';

const initialState = {
  getAllCoupons: [],
  getAllCouponsLoading: false,

  createCouponLoading: false,
  createCoupon: null,

  getCouponByIdLoading: false,
  getCouponById: null,

  deleteCouponLoading: false,
  deleteCoupon: null,

  updateCouponLoading: false,
  updateCoupon: null,

  getAllProvinces: [],
  getAllProvincesLoading: false,

  createProvinceLoading: false,
  createProvince: null,

  getProvinceByIdLoading: false,
  getProvinceById: null,

  deleteProvinceLoading: false,
  deleteProvince: null,

  updateProvinceLoading: false,
  updateProvince: null,

  getAllCities: [],
  getAllCityLoading: false,

  createCityLoading: false,
  createCity: null,

  getCityByIdLoading: false,
  getCityById: null,

  deleteCityLoading: false,
  deleteCity: null,

  updateCityLoading: false,
  updateCity: null,
};

export const GetAllCoupons = createAsyncThunk('coupon/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await couponService.getAllCoupons(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateCoupon = createAsyncThunk('coupon/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await couponService.createCoupon(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateCoupon = createAsyncThunk('coupon/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await couponService.updateCoupon(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetCouponById = createAsyncThunk('coupon/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await couponService.getAllCoupons(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteCoupon = createAsyncThunk('coupon/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await couponService.deleteCoupon(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const couponSlice = createSlice({
  name: 'Coupon',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCoupons.pending, (state) => {
        state.getAllCouponsLoading = true;
      })
      .addCase(GetAllCoupons.fulfilled, (state, action) => {
        
        state.getAllCouponsLoading = false;
        state.getAllCoupons = action.payload.data;
      })
      .addCase(GetAllCoupons.rejected, (state, action) => {
        state.getAllCouponsLoading = false;
        state.getAllCoupons = action.payload;
      })
      .addCase(CreateCoupon.pending, (state) => {
        state.createCouponLoading = true;
      })
      .addCase(CreateCoupon.fulfilled, (state, action) => {
        state.createCouponLoading = false;
        state.createCoupon = action.payload;
      })
      .addCase(CreateCoupon.rejected, (state, action) => {
        state.createCouponLoading = false;
        state.createCoupon = action.payload;
      })
      .addCase(UpdateCoupon.pending, (state) => {
        state.updateCouponLoading = true;
      })
      .addCase(UpdateCoupon.fulfilled, (state, action) => {
        state.updateCouponLoading = false;
        state.updateCoupon = action.payload;
      })
      .addCase(UpdateCoupon.rejected, (state, action) => {
        state.updateCouponLoading = false;
        state.updateCoupon = action.payload;
      })
      .addCase(GetCouponById.pending, (state, action) => {
        state.getCouponByIdLoading = true;
        state.getCouponById = action.payload;
      })
      .addCase(GetCouponById.fulfilled, (state, action) => {
        state.getCouponByIdLoading = false;
        state.getCouponById = action.payload;
      })
      .addCase(GetCouponById.rejected, (state, action) => {
        state.getCouponByIdLoading = false;
        state.getCouponById = action.payload;
      })
      .addCase(DeleteCoupon.pending, (state) => {
        state.deleteCouponLoading = true;
      })
      .addCase(DeleteCoupon.fulfilled, (state, action) => {
        state.deleteCouponLoading = false;
        state.deleteCoupon = action.payload;
      })
      .addCase(DeleteCoupon.rejected, (state, action) => {
        state.deleteCouponLoading = false;
        state.deleteCoupon = action.payload;
      });
  },
});

export const { reset } = couponSlice.actions;

export default couponSlice.reducer;

import RouterConfig from "../../RouterConfig";
import { useNavigate } from "react-router-dom";
import Services from "../../pages/services/Services";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import GoToWhatsapp from "../WhatsappVisible/GoToWhatsapp";
import "../../assets/css/cartModal.css";
// import Sidebar from "./SideBar";
// import swal from 'sweetalert2'
export function ParentComponentWithSideBar({ children, ...rest }) {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const unlisten = navigate((location) => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, [navigate]);
  return (
    <div
      className="main-wrapper"
      // style={{
      //   marginTop: "11rem",
      // }}
    >
      <Header />
      {/* <Sidebar /> */}
      {/*  <div className="page-wrapper"> */}
      <div>
        {children}
        {/* </div> */}
      </div>
      <Services />
      <Footer />
      <GoToWhatsapp />
    </div>
  );
}

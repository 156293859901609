/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import productService from './product.service';

const initialState = {
    getAllProducts: [],
    getAllProductsLoading: false,

    getAllFilteredProducts: [],
    getAllFilteredProductsLoading: false,

    getAllProductsByFilter: [],
    getAllProductsByFilterLoading: false,

    getAllSearchFilters: [],
    getAllSearchFiltersLoading: false,

    fetchProductsByFilter: [],
    fetchProductsByFilterLoading: false,

    getProductByIdLoading: false,
    getProductById: null,

};

export const GetAllProducts = createAsyncThunk('product/getAll', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.getAllProducts(data);
        if (response.status === 200) {
            
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const GetAllFilteredProducts = createAsyncThunk('filteredProduct/getAll', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.getAllFilteredProducts(data);
        
        if (response.status === 200) {
            
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const FetchProductsByFilter = createAsyncThunk('product/fetchByFilter', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.fetchProductsByFilter(data);

        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const GetAllSearchFilters = createAsyncThunk('Filters/AllFilters', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.getAllSearchFilters(data);
        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const GetAllProductsByFilter = createAsyncThunk('product/getAllByFilter', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.getAllProductsByFilter(data);

        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const GetProductById = createAsyncThunk('product/getById', async ({ data, moveToNext }, thunkAPI) => {
    try {
        const response = await productService.getProductById(+data);

        if (response.status === 200) {
            if (moveToNext) {
                moveToNext(response?.data?.message, 'success');
            }
        } else {
            moveToNext(response?.data?.message, 'error');
        }
        return response;
    } catch (error) {
        moveToNext(error?.message, 'error');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// export const ResetAllProductsByFilter = createAsyncThunk('product/resetAll', async ({ data, moveToNext }, thunkAPI) => {
//     try {
//     } catch (error) {
//         return thunkAPI.rejectWithValue(message);
//     }
// });

export const ResetAllProductsByFilter = createAction('REVERT_ALL')
export const ResetFetchProductsByFilter = createAction('RESET_PRODUCTS_BY_FILTER')
export const ResetGetAllFilteredProducts = createAction('RESET_GET_ALL_FILTERED_PRODUCTS')

export const ProductSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        // isLoading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllProducts.pending, (state) => {
                state.getAllProductsLoading = true;
            })
            .addCase(GetAllProducts.fulfilled, (state, action) => {
                state.getAllProductsLoading = false;
                state.getAllProducts = action.payload.data;
            })
            .addCase(GetAllProducts.rejected, (state, action) => {
                state.getAllProductsLoading = false;
                state.getAllProducts = action.payload;
            })
            .addCase(GetAllFilteredProducts.pending, (state) => {
                state.getAllFilteredProductsLoading = true;
            })
            .addCase(GetAllFilteredProducts.fulfilled, (state, action) => {
                state.getAllFilteredProductsLoading = false;
                state.getAllFilteredProducts = action.payload.data;
            })
            .addCase(GetAllFilteredProducts.rejected, (state, action) => {
                state.getAllFilteredProductsLoading = false;
                state.getAllFilteredProducts = action.payload;
            })
            .addCase(GetProductById.pending, (state, action) => {
                state.getProductByIdLoading = true;
                state.getProductById = action.payload;
            })
            .addCase(GetProductById.fulfilled, (state, action) => {
                state.getProductByIdLoading = false;
                state.getProductById = action.payload;
            })
            .addCase(GetProductById.rejected, (state, action) => {
                state.getProductByIdLoading = false;
                state.getProductById = action.payload;
            }).addCase(GetAllProductsByFilter.pending, (state) => {
                state.getAllProductsByFilterLoading = true;
            })
            .addCase(GetAllProductsByFilter.fulfilled, (state, action) => {
                state.getAllProductsByFilterLoading = false;
                state.getAllProductsByFilter = action.payload.data;
            })
            .addCase(GetAllProductsByFilter.rejected, (state, action) => {
                state.getAllProductsByFilterLoading = false;
                state.getAllProductsByFilter = action.payload;
            }).addCase(GetAllSearchFilters.pending, (state) => {
                state.getAllSearchFiltersLoading = true;
            })
            .addCase(GetAllSearchFilters.fulfilled, (state, action) => {
                state.getAllSearchFiltersLoading = false;
                state.getAllSearchFilters = action.payload.data;
            })
            .addCase(GetAllSearchFilters.rejected, (state, action) => {
                state.getAllSearchFiltersLoading = false;
                state.getAllSearchFilters = action.payload;
            }).addCase(FetchProductsByFilter.pending, (state) => {
                state.fetchProductsByFilterLoading = true;
            })
            .addCase(FetchProductsByFilter.fulfilled, (state, action) => {
                state.fetchProductsByFilterLoading = false;
                state.fetchProductsByFilter = action.payload.data;
            })
            .addCase(FetchProductsByFilter.rejected, (state, action) => {
                state.fetchProductsByFilterLoading = false;
                state.fetchProductsByFilter = action.payload;
            })
            .addCase(ResetFetchProductsByFilter, (state) => {
                state.fetchProductsByFilterLoading = initialState.fetchProductsByFilterLoading;
                state.fetchProductsByFilter = initialState.fetchProductsByFilter;
            })
            .addCase(ResetGetAllFilteredProducts, (state) => {
                state.getAllFilteredProductsLoading = initialState.getAllFilteredProductsLoading;;
                state.getAllFilteredProducts = initialState.getAllFilteredProducts ;
            })
            .addCase(ResetAllProductsByFilter, (state) => {
                state.getAllProductsByFilterLoading = initialState.getAllProductsByFilterLoading;
                state.getAllProductsByFilter = initialState.getAllProductsByFilter;
            });
    },
});

export const { reset } = ProductSlice.actions;

export default ProductSlice.reducer;
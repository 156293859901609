import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import "../../assets/css/services.css";
import ReactReadMoreReadLess from "react-read-more-read-less";


const SubServices = ({ cardData }) => {
  return (
    <div
      className=" mb-2 "
    // style={{ width: "100%" }}
    >
      <div className="card-body d-flex flex-column">
        {/* <Row> */}
        <Image src={cardData.image} alt="Icon" className="mb-2" />
        {/* </Row> */}
        <div>
          <h5 className="card-title text-primary mb-1">{cardData.heading}</h5>
        </div>
        <Row style={{ overflow: 'hidden', }}>
          <div className="mobile-view-text text-center ">
            <p className="text-primary overflow-ellipsis"
              style={{ textAlign: 'left', margin: 0, lineHeight: 1.3, wordWrap: 'break-word' }}>
              {/* {cardData.text} */}
              <ReactReadMoreReadLess
                charLimit={100}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
              >
                {cardData.text}
              </ReactReadMoreReadLess>

            </p>
          </div>
        </Row>
      </div>

    </div>
  );
};

export default SubServices;

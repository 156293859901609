import { toast } from "react-toastify";
import Swal from 'sweetalert2';

function Notification(data, condition) {
  if (condition === "success") {
    toast.success(data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "print-data",
      progress: undefined,
      theme: "light",
      //   theme: "colored",
    });
  } else {
    toast.error(data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
export function Alert(onConfirm, title = 'Are you sure?', text = "You won't be able to revert this!", icon = 'warning', confirmButtonText = 'Okay') {
  Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
    // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
  });
}
export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
export { Notification };

// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ParentComponentWithSideBar } from "../components/layout/ParentComponentWithSideBar";
// import { useEffect } from "react";
// import FullscreenLoader from "./FullScreenLoader.jsx";
// import PATH from "../../utils/path.jsx";

function PublicRoute({ element }) {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (navigate) {
  //     navigate("/user");
  //   }
  // }, [navigate]);
  // if (user === undefined) return <FullscreenLoader />;

  return <ParentComponentWithSideBar>{element}</ParentComponentWithSideBar>;
}

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PublicRoute;

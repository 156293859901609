/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dynamicFieldService from './dynamicFields.service';

const initialState = {
  getAllFields: [],
  getAllFieldsLoading: false,

  createFieldLoading: false,
  createField: null,

  getFieldByIdLoading: false,
  getFieldById: null,

  deleteFieldLoading: false,
  deleteField: null,

  updateFieldLoading: false,
  updateField: null,

  getAllOptions: [],
  getAllOptionsLoading: false,

  createOptionLoading: false,
  createOption: null,

  getOptionByIdLoading: false,
  getOptionById: null,

  deleteOptionLoading: false,
  deleteOption: null,

  updateOptionLoading: false,
  updateOption: null,
};

export const GetAllFields = createAsyncThunk('dynamicField/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.getAllFields(data);
    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateField = createAsyncThunk('dynamicField/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.createField(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateField = createAsyncThunk('dynamicField/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.updateField(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetFieldById = createAsyncThunk('dynamicField/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.getAllFields(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteField = createAsyncThunk('dynamicField/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.deleteField(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetAllOptions = createAsyncThunk('option/getAll', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.getAllOptions(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateOption = createAsyncThunk('option/create', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.createOption(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const UpdateOption = createAsyncThunk('option/update', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.updateOption(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetOptionById = createAsyncThunk('option/getById', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.getAllOptions(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DeleteOption = createAsyncThunk('option/delete', async ({ data, moveToNext }, thunkAPI) => {
  try {
    const response = await dynamicFieldService.deleteOption(data);

    if (response.status === 200) {
      if (moveToNext) {
        moveToNext(response?.data?.message, 'success');
      }
    } else {
      moveToNext(response?.data?.message, 'error');
    }
    return response;
  } catch (error) {
    moveToNext(error?.message, 'error');
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const dynamicFieldSlice = createSlice({
  name: 'Field',
  initialState,
  reducers: {
    // isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllFields.pending, (state) => {
        state.getAllFieldsLoading = true;
      })
      .addCase(GetAllFields.fulfilled, (state, action) => {
        state.getAllFieldsLoading = false;
        state.getAllFields = action.payload.data;
      })
      .addCase(GetAllFields.rejected, (state, action) => {
        state.getAllFieldsLoading = false;
        state.getAllFields = action.payload;
      })
      .addCase(CreateField.pending, (state) => {
        state.createFieldLoading = true;
      })
      .addCase(CreateField.fulfilled, (state, action) => {
        state.createFieldLoading = false;
        state.createField = action.payload;
      })
      .addCase(CreateField.rejected, (state, action) => {
        state.createFieldLoading = false;
        state.createField = action.payload;
      })
      .addCase(UpdateField.pending, (state) => {
        state.updateFieldLoading = true;
      })
      .addCase(UpdateField.fulfilled, (state, action) => {
        state.updateFieldLoading = false;
        state.updateField = action.payload;
      })
      .addCase(UpdateField.rejected, (state, action) => {
        state.updateFieldLoading = false;
        state.updateField = action.payload;
      })
      .addCase(GetFieldById.pending, (state, action) => {
        state.getFieldByIdLoading = true;
        state.getFieldById = action.payload;
      })
      .addCase(GetFieldById.fulfilled, (state, action) => {
        state.getFieldByIdLoading = false;
        state.getFieldById = action.payload;
      })
      .addCase(GetFieldById.rejected, (state, action) => {
        state.getFieldByIdLoading = false;
        state.getFieldById = action.payload;
      })
      .addCase(DeleteField.pending, (state) => {
        state.deleteFieldLoading = true;
      })
      .addCase(DeleteField.fulfilled, (state, action) => {
        state.deleteFieldLoading = false;
        state.deleteField = action.payload;
      })
      .addCase(DeleteField.rejected, (state, action) => {
        state.deleteFieldLoading = false;
        state.deleteField = action.payload;
      })
      .addCase(GetAllOptions.pending, (state) => {
        state.getAllOptionsLoading = true;
      })
      .addCase(GetAllOptions.fulfilled, (state, action) => {
        state.getAllOptionsLoading = false;
        state.getAllOptions = action.payload.data.data;
      })
      .addCase(GetAllOptions.rejected, (state, action) => {
        state.getAllOptionsLoading = false;
        state.getAllOptions = action.payload;
      })
      .addCase(CreateOption.pending, (state) => {
        state.createOptionLoading = true;
      })
      .addCase(CreateOption.fulfilled, (state, action) => {
        state.createOptionLoading = false;
        state.createOption = action.payload.data.data;
      })
      .addCase(CreateOption.rejected, (state, action) => {
        state.createOptionLoading = false;
        state.createOption = action.payload;
      })
      .addCase(UpdateOption.pending, (state) => {
        state.updateOptionLoading = true;
      })
      .addCase(UpdateOption.fulfilled, (state, action) => {
        state.updateOptionLoading = false;
        state.updateOption = action.payload.data.data;
      })
      .addCase(UpdateOption.rejected, (state, action) => {
        state.updateOptionLoading = false;
        state.updateOption = action.payload;
      })
      .addCase(GetOptionById.pending, (state, action) => {
        state.getOptionByIdLoading = true;
        state.getOptionById = action.payload;
      })
      .addCase(GetOptionById.fulfilled, (state, action) => {
        state.getOptionByIdLoading = false;
        state.getOptionById = action.payload.data.data;
      })
      .addCase(GetOptionById.rejected, (state, action) => {
        state.getOptionByIdLoading = false;
        state.getOptionById = action.payload;
      })
      .addCase(DeleteOption.pending, (state) => {
        state.deleteOptionLoading = true;
      })
      .addCase(DeleteOption.fulfilled, (state, action) => {
        state.deleteOptionLoading = false;
        state.deleteOption = action.payload.data.data;
      })
      .addCase(DeleteOption.rejected, (state, action) => {
        state.deleteOptionLoading = false;
        state.deleteOption = action.payload;
      });
  },
});

export const { reset } = dynamicFieldSlice.actions;

export default dynamicFieldSlice.reducer;

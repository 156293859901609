import React, { useEffect } from "react";
import Footlogo from "../../assets/images/Varskart-Logo.png";

import "../../assets/css/Footer.css";
import images from '../../assets/images/ImageImporter/images'
import Facebook from "../../assets/images/Facebook.png";
import Twitter from "../../assets/images/Twitter.png";
import Linkedin from "../../assets/images/Linkedin.png";
import Icon_8 from "../../assets/images/Icon 8.png";
import Icon_9 from "../../assets/images/Icon 9.png";
import Icon_10 from "../../assets/images/Icon 10.png";
import Icon_11 from "../../assets/images/Icon 11.png";
import {
  Col,
  Container,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();

  function handleNavClick() {
    // Toggle Navbar when a Nav.Link is clicked
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    // const navbarToggle = document.getElementsByClassName('navbar-toggler')[0];
    // navbarToggle.click();
  }
  return (
    <footer
      className="text-light footer"
    >
      <div>

        <div className="bg-clr row ">

          <div className="container bg-white rounded  shadow-lg "
            style={{
              width: "85%",
              padding: "20px",
              marginTop: "-45px"
            }}
          >
            <Row className=" w-auto d-flex justify-content-center ">
              <Col sm={12} md={6} lg={3} className=" d-flex justify-content-center align-items-center border-end ">
                <div className="text-center d-flex justify-content-center   ">
                  <img src={Icon_8} alt="Icon 1" className="servicesIMG" />
                  <p className="p-0 m-0 fw-bold text-nowrap text-black">Easy to Buy</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3} className="d-flex justify-content-center align-items-center border-end ">
                <div className="text-center d-flex justify-content-center ">
                  <img src={Icon_9} alt="Icon 1" className="servicesIMG" />
                  <p className="p-0 m-0 fw-bold text-nowrap text-black">Original Products</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3} className="d-flex justify-content-center align-items-center border-end ">
                <div className="text-center d-flex justify-content-center align-content-start w-auto  ">
                  <div className="px-0 d-flex align-content-start ">

                    <img src={Icon_10} alt="Icon 1" className="servicesIMG" />
                    <h6 className="p-0 m-0 fw-bold  text-black">Warranty Against Defects</h6>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3} className="d-flex justify-content-center align-items-center  ">
                <div className="text-center d-flex justify-content-center ">
                  <img src={Icon_11} alt="Icon 1" className="servicesIMG" />
                  <p className="p-0 m-0 fw-bold text-nowrap text-black">Dedicated Support</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="container mb-4 mt-5">
            <Row className="mt-2 mx-4">
              <Col md={3} className="d-flex justify-content-center align-items-center  ">
                <div className="footer-logo w-75 mb-3 ">
                  <Image
                    className="justify-content-md-center img-fluid"
                    src={Footlogo}
                    alt="Logo"
                    fluid
                    style={{ maxwidth: '100%', height: 'auto', objectFit: "cover" }}
                  // style={{ objectFit: "cover"}}
                  />
                </div>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={4}>
                    <Row className=" my-2 ">
                      <Col md={12}>
                        <h5 className="clr mb-3 ">Contact Us</h5>
                      </Col>
                    </Row>
                    <Row>
                      <div className="list-unstyled mb-3 ">
                        <li className="mb-4 d-flex">
                          <span className="border border-success rounded-circle d-flex justify-content-center " style={{ width: "20px", height: '20px' }}><img style={{ width: "13px" }} src={images.Map} /> </span>
                          <div>
                          <div style={{ fontSize: "14px"}}>Vars Kart General Trading LLC, </div>
                          <div style={{ fontSize: "14px"}}> 709, Al Qusais Plaza, </div>
                          <div style={{ fontSize: "14px"}}>  Damascus Street, </div>
                          <div style={{ fontSize: "14px"}}>  P.O. Box: 236438, Dubai, </div>
                          <div style={{ fontSize: "14px"}}>  United Arab Emirates</div>
                          </div>
                              </li>
                              <li className="d-flex"><span className="border border-success rounded-circle d-flex justify-content-center " style={{ width: "20px", height: '20px' }}><img style={{ width: "12px" }} src={images.Call} /> </span><a href="tel:+971566031925"  target="_blank" style={{ fontSize: "14px"}} className="text-decoration-none text-white ">+971566031925</a></li>
                              <li className="d-flex"><span className="border border-success rounded-circle d-flex justify-content-center " style={{ width: "20px", height: '20px' }}><img style={{ width: "13px" }} src={images.Email} /> </span><a href="mailto:support@varskart.com" style={{ fontSize: "14px"}} className="text-decoration-none text-white "> support@varskart.com</a></li>
                            </div>
                          </Row>
                          </Col>
                          <Col md={8}>
                            <Row className=" my-2 ">
                              <Col md={12}>
                                <h5 className="clr mb-3 ">USEFUL LINKS</h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <div className=" mb-3 ">
                                  <div>
                                    <Link className="text-decoration-none text-white "
                                      to="/"
                                      onClick={() => { handleNavClick(); }}
                                    >
                                      <span style={{ fontSize: "14px"}}> Home</span>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link className="text-decoration-none text-white "
                                      to="/about_us"
                                      onClick={() => { handleNavClick(); }}
                                    >
                                      <span style={{ fontSize: "14px"}} >About us</span>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link className="text-decoration-none text-white "
                                      to="/blogs"
                                      onClick={() => { handleNavClick(); }}
                                    >
                                      <span style={{ fontSize: "14px"}} >Blogs</span>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div>
                                  <Link 
                                  className="text-decoration-none text-white " 
                                  to="/privacy-policy"
                                  onClick={() => { handleNavClick(); }}
                                  >
                                    <span style={{ fontSize: "14px"}} >Privacy Policy</span>
                                  </Link>
                                </div>
                                <div>
                                  <Link className="text-decoration-none text-white " 
                                  to="/delivery-policy"
                                  onClick={() => { handleNavClick(); }}
                                  >
                                    <span style={{ fontSize: "14px"}}>Delivery Policy</span>
                                  </Link>
                                </div>
                                <div>
                                  <Link className="text-decoration-none text-white " 
                                  to="/terms-and-conditions"
                                  onClick={() => { handleNavClick(); }}
                                  >
                                    <span style={{ fontSize: "14px"}}>Terms & Conditions</span>
                                  </Link>
                                </div>
                                <div>
                                  <Link className="text-decoration-none text-white " 
                                  to="/become-a-supplier"
                                  onClick={() => { handleNavClick(); }}
                                  >
                                    <span style={{ fontSize: "14px"}}>Become a Supplier</span>
                                  </Link>
                                </div>
                                <div>
                                  <Link className="text-decoration-none text-white " 
                                  to="/meeting-request"
                                  onClick={() => { handleNavClick(); }}
                                  >
                                    <span style={{ fontSize: "14px"}}>Request for Meeting</span>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="gradient ">
                  <Container className="Container">
                    <Row className="">
                      <Col md={6}>
                        <div className="d-flex align-items-center mt-3 mb-3">
                          <div className="mr-3" style={{ fontSize: "14px"}}>Follow Us on Social Media</div>
                          <div className="social-icons">
                            {/* <Link to="#">
                      <img
                        src={Facebook}
                        alt="Facebook"
                        className="social-icon "
                        style={{ width: "30px", height: "30px" }}
                      />{" "}
                    </Link> */}
                            <a href="https://www.linkedin.com/company/varskart-com/" target="_blank">
                              <img
                                src={Linkedin}
                                alt="Linkedin"
                                className="social-icon "
                                style={{ width: "25px", height: "25px" }}
                              />{" "}
                            </a>
                            {/* <Link to="#">
                      <img
                        src={Twitter}
                        alt="Twitter"
                        className="social-icon"
                        style={{ width: "30px", height: "30px" }}
                      />{" "}
                    </Link> */}
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex align-items-center mt-3 mb-3">
                          <div className="mr-3" style={{ fontSize: "14px"}}>Subscribe to Varskart</div>
                          <InputGroup
                            className="input"
                            style={{ maxWidth: "280px", marginRight: "5px" }}
                          >
                            <FormControl
                              placeholder="Please Enter Your Email"
                              aria-label="Subscribe"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="bg-danger rounded-0 border-0 text-white"
                            >
                              Subscribe
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </footer>
            );
};

            export default Footer;
